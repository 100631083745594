import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { BULK_USER_UPLOAD } from "../../graphql/userQueries";
import { useToast } from "../../components/Toast/toastHook";
import { GET_COMPANY_USERS } from "../../graphql/companyQueries";
import { Button, Col, Modal, Row, Select } from "antd";

const ACCEPTED_COLUMNS = ["FirstName", "LastName", "Email", "Phone", "Office"];

function getInitialMappedColumns() {
  return ACCEPTED_COLUMNS.map((c) => ({ actual: c, mapped: null }));
}

const BulkUserUpload = (props) => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const fileReader = new FileReader();
  const { companyId } = useParams();
  const toast = useToast(4000);
  const [loading, setLoading] = useState(false);
  const [notSaved, setNotSaved] = useState([]);
  const [totalSaved, setTotalSaved] = useState("");
  const [results, setResults] = useState(false);
  const [open, setOpen] = useState(false);

  const [mappedColumns, setMappedColumns] = useState(getInitialMappedColumns());

  const [bulkUserUpload, { error, data }] = useMutation(BULK_USER_UPLOAD, {
    refetchQueries: [{ query: GET_COMPANY_USERS, variables: { companyId } }],
    onCompleted(res) {
      toast("success", "User upload has completed!");
      setTotalSaved(res.bulkUserUpload?.totalSaved);
      setNotSaved(res.bulkUserUpload?.notSaved);
      setResults(true);
      setFile("");
      setArray([]);
      props.refetchUsers({ companyId });
    },
    onError(e) {
      console.error(e);
      toast(
        "error",
        "There was an error on save. Please check the csv and try again"
      );
    },
  });

  const onCancel = () => {
    props.onCancel();
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header.split(" ").join(" ").trim()] = values[index]
          ?.trim()
          .replace(/['"]+/g, "");
        return object;
      }, {});

      return { ...obj };
    });

    // setLoading(true);
    // setTimeout(() => {
    setArray(array);
    //   setLoading(false);
    // }, 2000);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const handleUserUpload = () => {
    if (window.confirm("Would you like to upload all users?")) {
      bulkUserUpload({
        variables: { userBulk: array },
      });
    }
  };

  const getAvailableColumns = () => {
    const columns = ACCEPTED_COLUMNS.map((c) => ({ label: c, value: c }));

    return columns.filter(
      (c) => !mappedColumns.find((mc) => mc.actual === c.label)?.mapped
    );
  };

  const selectColumn = (selected, index) => {
    setMappedColumns((prev) => {
      const temp = [...prev];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].actual === selected) {
          temp[i].mapped = Object.keys(array[0])[index];
        }
      }
      return temp;
    });
  };

  const sanitizeColumnNames = () => {
    const updatedData = array.map((item) => {
      const updatedItem = { Company: companyId };

      mappedColumns.forEach((mapping) => {
        const { actual, mapped } = mapping;

        updatedItem[actual] = item[mapped];
      });

      return updatedItem;
    });

    setOpen(false);

    setArray(updatedData);
  };

  const checkColumnNames = () => {
    if (array.length <= 0) return false;

    let status;

    const validColumNames = mappedColumns.map((s) => s.actual);

    Object.keys(array[0]).map((key) => {
      if (!validColumNames.includes(key)) {
        status = false;
      } else {
        status = true;
      }
    });

    return status;
  };

  return (
    <>
      <Breadcrumb pageName={"Upload Users"} />
      <div className="px-6">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-4">
            <h1 className="text-lg">
              To import multiple agents please upload a CSV file with the
              following columns:
            </h1>
            <ol className="px-2 mt-4">
              <li className="p-2">
                <span className="text-red text-sm">*</span>First Name
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Last Name
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Email
              </li>
              <li className="p-2">
                <span className="text-red text-sm">*</span>Phone
              </li>
              {/* <li className="p-2">License Number</li> */}
              <li className="p-2">
                <span className="text-red text-sm">*</span>Default Office
              </li>
            </ol>
            <div className="text-red text-sm ml-4">*required fields</div>

            <div className="flex justify-start align-center space-x-3 items-center mt-4">
              {/* <button
                className={`${!file ? "bg-light_gray" : "bg-primary"} rounded text-white w-1/4 `}
                disabled={!file}
                onClick={handleOnSubmit}
              >
                Import CSV
              </button> */}
              <Button
                // disabled={!file}
                // onClick={handleOnSubmit}
                onClick={(e) => {
                  setOpen(true);
                  handleOnSubmit(e);
                }}
                size="default"
                className="bg-primary"
              >
                Import CSV
              </Button>
              <Button
                onClick={() => onCancel()}
                size="default"
                className="secondary-btn"
              >
                CANCEL
              </Button>
            </div>
            <input
              type="file"
              className="border rounded p-2 w-3/4 my-3"
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-full md:w-1/2 p-4 bg-light_gray rounded text-xl">
            <h1 className={`${results ? `text-primary` : "text-red"} text-3xl`}>
              Results {results ? " Completed" : " Pending"}
            </h1>
            <ul>
              <li className="py-4">
                Total Agents to Save:{" "}
                {results ? totalSaved + notSaved.length : array.length}
              </li>
              <li className="py-4">
                Total Saved: {results ? totalSaved : "--"}
              </li>
              <li className="py-4">
                Total Not Saved: {results ? notSaved.length : "--"}
              </li>
              <li className="py-4 flex flex-wrap">
                Agents not saved:{" "}
                {results
                  ? notSaved.map((ns, i) => (
                      <div
                        className="px-2"
                        key={i}
                      >{`${ns["FirstName"]} ${ns["LastName"]}`}</div>
                    ))
                  : "--"}
              </li>
            </ul>
          </div>
        </div>
        <div className="p-4 w-full">
          {loading && (
            <div className="w-full flex text-center justify-center my-4">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-primary animate-spin fill-secondary
                "
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        title={"Import Agents"}
        onCancel={() => setOpen(false)}
        footer={() => (
          <div className="mt-3 flex">
            <Button
              type="primary"
              className="border-primary text-primary w-[73px]"
              onClick={sanitizeColumnNames}
            >
              Import
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </div>
        )}
      >
        <div className="mt-4">
          <Row className="mb-4">
            <Col span={8}>
              <div className="font-semibold">Column Name</div>
            </Col>
            <Col span={8}>
              <div className="font-semibold">Accepted Column</div>
            </Col>
            <Col span={8}>
              <div className="font-semibold">Status</div>
            </Col>
          </Row>
          {array.length
            ? Object.keys(array[0]).map((label, index) => (
                <Row className="mb-4" key={`${label}_${index}`}>
                  <Col span={8}>{label}</Col>
                  <Col span={8}>
                    <Select
                      // style={{
                      //   width: "100%",
                      //   height: "50px",
                      // }}
                      showSearch
                      placeholder="Select Option"
                      // filterOption={filterOptions}
                      value={
                        mappedColumns.find((mc) => mc.mapped === label)
                          ?.actual || null
                      }
                      options={getAvailableColumns()}
                      onChange={(selected) => selectColumn(selected, index)}
                    />
                  </Col>
                  <Col span={8} className="pl-4">
                    <Button
                      type="primary"
                      onClick={() => {
                        setMappedColumns((prev) => {
                          const temp = [...prev];
                          const tIndex = temp.findIndex(
                            (mc) => mc?.mapped === label
                          );
                          if(tIndex > -1) temp[tIndex].mapped = null;
                          return temp;
                        });
                      }}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              ))
            : null}
        </div>
      </Modal>
      {!checkColumnNames() ? null : array.length > 0 ? (
        <>
          <div className="text-2xl my-4 border-b  border-black p-4">
            <span>
              {array.length <= 10
                ? "Preview of the data: "
                : "Preview of the first 10 rows of your data: "}
            </span>
            <button
              onClick={handleUserUpload}
              className="text-blue hover:cursor-pointer hover:text-opacity-80 underline"
            >
              {`Upload all ${array.length} agents?`}
            </button>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                {/* <th>License Number</th> */}
                <th>Office</th>
              </tr>
            </thead>
            <tbody>
              {array.slice(0, 10).map((d, i) => (
                <tr className="text-center" key={i}>
                  <td>{d["FirstName"]}</td>
                  <td>{d["LastName"]}</td>
                  <td>{d["Email"]}</td>
                  <td>{d["Phone"]}</td>
                  {/* <td>{d["LicenseNumber"]}</td> */}
                  <td>{d["Office"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
};

export default BulkUserUpload;

// {array.length > 0 ? (
//   <>
//     <div className="text-2xl my-4 border-b  border-black p-4">
//       <span>
//         {array.length <= 10
//           ? "Preview of the data: "
//           : "Preview of the first 10 rows of your data: "}
//       </span>
//       <button
//         onClick={handleUserUpload}
//         className="text-blue hover:cursor-pointer hover:text-opacity-80 underline"
//       >
//         {`Upload all ${array.length} agents?`}
//       </button>
//     </div>
//     <table className="w-full">
//       <thead>
//         <tr>
//           <th>First Name</th>
//           <th>Last Name</th>
//           <th>Email</th>
//           <th>Phone</th>
//           {/* <th>License Number</th> */}
//           <th>Office</th>
//         </tr>
//       </thead>
//       <tbody>
//         {array.slice(0, 10).map((d, i) => (
//           <tr
//             className="text-center"
//             key={i}
//           >
//             <td>{d["FirstName"]}</td>
//             <td>{d["LastName"]}</td>
//             <td>{d["Email"]}</td>
//             <td>{d["Phone"]}</td>
//             {/* <td>{d["LicenseNumber"]}</td> */}
//             <td>{d["Office"]}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </>
// ) : null}
