import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_COMPANY_OFFICES,
  GET_COMPANY_USERS,
} from "../../graphql/companyQueries";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  message,
  Select,
  Space,
  Transfer,
} from "antd";
import Breadcrumb from "../Breadcrumb";
import { ADD_USER, UPDATE_USER } from "../../graphql/userQueries";
import { useToast } from "../../components/Toast/toastHook";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function UserFormNew(props) {
  const [form] = Form.useForm();
  const { companyId, handleOnCancel, selectedUser, refetchUsers } = props;

  const [userType, setUserType] = useState("");
  const { loading, data } = useQuery(GET_COMPANY_OFFICES, {
    variables: { companyId },
  });
  const toast = useToast(4000);

  const [addUser, { loading: addLoading }] = useMutation(ADD_USER, {
    // refetchQueries: [
    //   {
    //     query: GET_COMPANY_USERS,
    //     variables: {
    //       companyId,
    //     },
    //   },
    // ],
    onCompleted(result) {
      toast(
        "success",
        `Successfully saved ${result.addUser?.firstName} ${result.addUser?.lastName}!`
      );
      // navigate(0);
      refetchUsers({ companyId });

      handleOnCancel();
    },
    onError(e) {
      console.log(e.message);
      toast("error", e?.message ?? "Something went wrong!");
    },
  });

  const [updateUser, { loading: editLoading }] = useMutation(UPDATE_USER, {
    // refetchQueries: [
    //   {
    //     query: GET_COMPANY_USERS,
    //     variables: {
    //       companyId,
    //     },
    //   },
    // ],
    onCompleted(result) {
      toast(
        "success",
        `Successfully saved ${result.updateUser?.firstName} ${result.updateUser?.lastName}!`
      );
      // navigate(0);
      refetchUsers({ companyId });

      handleOnCancel();
    },
    onError(e) {
      console.log(e);
      toast("error", e?.message ?? "Something went wrong!");
    },
  });

  const onSubmit = (values) => {
    if (selectedUser) {
      //update
      updateUser({
        variables: {
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: +values.phone.replace(/[^0-9\.]+/g, ""),
            defaultOffice: values.defaultOffice._id,
            licenseNo: values.licenseNo,
            role: values.role._id,
            _id: selectedUser.id,
            company: companyId,
          },
        },
      });
    } else {
      console.log(values);
      //create
      addUser({
        variables: {
          userInput: {
            ...values,
            company: companyId,
            phone: +values.phone.replace(/[^0-9\.]+/g, ""),
            role: values.role._id,
            defaultOffice: values.defaultOffice._id,
          },
        },
      });
    }
  };
  const userFormValidation = {
    agentsToPostAs: [
      {
        required: true,
        message: "Agents to post required.",
      },
    ],
    assignedOffices: [
      {
        required: true,
        message: "Assigned offices required.",
      },
    ],
    defaultOffice: [
      {
        required: true,
        message: "Default office required.",
      },
    ],
    email: [
      {
        required: true,
        message: "Email required.",
      },
      {
        type: "email",
        message: "Not a valid email.",
      },
    ],
    firstName: [
      {
        required: true,
        message: "First name required.",
      },
    ],
    lastName: [
      {
        required: true,
        message: "Last name required.",
      },
    ],
    licenseNo: [
      {
        required: false,
        message: "License number required.",
      },
    ],
    phone: [
      {
        required: true,
        message: "Phone number required.",
      },
      {
        transform: (value) => value?.replace(/[^0-9\.]+/g, ""),
        pattern: /^[0-9]{10}$/g,
        message: "Please enter a ten digit number.",
      },
    ],
    role: [
      {
        required: true,
        message: "Role required.",
      },
    ],
  };
  const formatPhoneNumber = (value) => {
    let formattedNumber = "";

    // Filter non-numbers
    const regex = () => value?.replace(/[^0-9\.]+/g, "");

    const areaCode = () => `(${regex().slice(0, 3)})`;

    const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

    // Dynamic trail as user types
    const trailer = (start) => `${regex().slice(start, regex().length)}`;

    // First 3 digits
    if (regex().length <= 3) formattedNumber = regex();
    // After area code
    else if (regex().length >= 4 && regex().length <= 6)
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    // After dash
    else if (regex().length >= 7)
      formattedNumber = `${firstSix()}-${trailer(6)}`;

    return formattedNumber;
  };
  useEffect(() => {
    if (props.selectedUser != null) {
      // Format phone number if editing user
      if (selectedUser.phone) {
        form.setFieldsValue({ phone: formatPhoneNumber(selectedUser.phone) });
      }
    }
  }, [props]);
  return (
    <div>
      <Breadcrumb pageName={`${!selectedUser ? "Add" : "Update"} User`} />
      <div className="user-form p-4">
        <Form
          initialValues={selectedUser}
          form={form}
          layout="vertical"
          size="default"
          onFinish={(values) => onSubmit(values)}
          onFieldsChange={(changedFields, _) => {
            const { name, value } = changedFields[0];
            if (name[0] === "phone")
              form.setFieldsValue({ phone: formatPhoneNumber(value) });
          }}
        >
          <div className="form-container form-container--two-column">
            <Form.Item
              label="Role"
              name={["role", "_id"]}
              rules={userFormValidation.role}
            >
              <Select className="select-style" size="large">
                {/* need to bring the roles dynamic here  */}
                <Select.Option value="65591bc8c4b9bb58fd2834a0">
                  Admin 1
                </Select.Option>
                <Select.Option value="649b89b497d37a86f3b3b4fd">
                  Admin
                </Select.Option>
                <Select.Option value="649b89b497d37a86f3b3b4fe">
                  Agent
                </Select.Option>
              </Select>
            </Form.Item>
            {!loading && data.getCompanyOffice.length >= 1 && (
              <Form.Item
                label="Default Office"
                name={["defaultOffice", "_id"]}
                rules={userFormValidation.defaultOffice}
              >
                <Select className="select-style" size="large">
                  {data.getCompanyOffice.map((office) => (
                    <Select.Option key={office._id}>
                      {office.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="First Name"
              name="firstName"
              rules={userFormValidation.firstName}
            >
              <Input className="input-style" size="large" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={userFormValidation.lastName}
            >
              <Input className="input-style" size="large" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={userFormValidation.email}
            >
              <Input className="input-style" size="large" />
            </Form.Item>

            {/* <Form.Item
              initialValue={selectedUser ? selectedUser.email : ""}
              label="Confirm Email"
              name="confirmEmail"
              rules={[
                ...userFormValidation.email,
                ({ getFieldValue }) => ({
                  validator: (_, value) =>
                    !value
                      ? Promise.resolve()
                      : getFieldValue("email") === value
                      ? Promise.resolve()
                      : Promise.reject(new Error("The two emails do not match.")),
                }),
              ]}
            >
              <Input className="input-style" size="large" />
            </Form.Item> */}

            <Form.Item
              label="Phone Number"
              name="phone"
              rules={userFormValidation.phone}
            >
              <Input className="input-style" maxLength={14} size="large" />
            </Form.Item>

            <Form.Item
              label="License Number"
              name="licenseNo"
              rules={userFormValidation.licenseNo}
            >
              <Input className="input-style" size="large" />
            </Form.Item>
          </div>
          <div className="flex space-x-2 justify-end">
            <Button
              onClick={() => handleOnCancel()}
              size="default"
              className="secondary-btn"
            >
              CANCEL
            </Button>

            <Button
              className="border-primary text-primary"
              htmlType="submit"
              size="default"
              type="primary"
              disabled={addLoading ? true : editLoading ? true : false}
            >
              {!selectedUser ? (
                addLoading ? (
                  <div className="animate-spin">
                    <AiOutlineLoading3Quarters size={22} />
                  </div>
                ) : (
                  "CREATE"
                )
              ) : editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "SAVE"
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default UserFormNew;
