import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
// import { GET_COMPANIES } from "../../graphql/companyQueries";
import Spinner from "../../components/Spinner";
import { GET_SUPER_DASH_DETAILS } from "../../graphql/companyQueries";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import MobileCard from "../../components/dashboard/MobileCard";
import { useToast } from "../../components/Toast/toastHook";
import { Line, LineChart, XAxis, YAxis, Tooltip } from "recharts";
const AdminDashboard = () => {
  // const { loading, error, data } = useQuery(GET_COMPANY_USERS, {
  // 	variables: { companyId: userData.company._id },
  // });
  const { loading, error, data } = useQuery(GET_SUPER_DASH_DETAILS, {
    fetchPolicy: "no-cache",
  });

  const toast = useToast();

  if (error) toast("error", error.message);

  const [dashDetails, setDashDetails] = useState({
    totalActiveUsers: 0,
    totalAccountsAssigned: 0,
    percentAccountsActivated: 0,
    totalActiveListings: 0,
    totalListings: 0,
    agentsEnteringListing: 0,
    agentsEnteringBuyers: 0,
    totalBuyersEntered: 0,
    totalBuyerMatches: 0,
    listingsEntered: [],
    buyersMatches: [],
  });

  useEffect(() => {
    if (data) setDashDetails(data.getSuperUserDashboardDetails);
  }, [data]);

  if (loading) return <Spinner />;
  return (
    <div className="">
      <Breadcrumb pageName={"Dashboard"} />

      <div className="flex w-full gap-3">
        <div className="min-w min-w-[1350px]">
          <Card>
            <div className="p-6">
              <div className="border-b-2 border-primary pb-2 mb-6 flex w-full justify-between items-center">
                <h1 className="text-3xl font-semibold text-secondary">
                  Overview
                </h1>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                  <MobileCard
                    text="User Count"
                    value={dashDetails.totalActiveUsers}
                  />
                  <MobileCard
                    text="Accounts Assigned"
                    value={dashDetails.totalAccountsAssigned}
                  />
                  <MobileCard
                    text="Accounts Activated %"
                    value={`${dashDetails.percentAccountsActivated?.toFixed(
                      2
                    )}%`}
                  />
                  <MobileCard
                    text="Total Listings Entered"
                    value={dashDetails.totalListings}
                  />
                  <MobileCard
                    text="Active Listings"
                    value={dashDetails.totalActiveListings}
                  />
                </div>
                <div className="flex flex-row gap-4">
                  <MobileCard
                    text="Total Agents Entering Listings"
                    value={`${dashDetails.agentsEnteringListing?.toFixed(2)}%`}
                  />
                  <MobileCard
                    text="Total Agents Entering Buyers"
                    value={`${dashDetails.agentsEnteringBuyers?.toFixed(2)}%`}
                  />
                  <MobileCard
                    text="Total # of Buyer Matches"
                    value={dashDetails.totalBuyerMatches}
                  />
                  <MobileCard
                    text="Total # of Buyers Entered"
                    value={dashDetails.totalBuyersEntered}
                  />
                </div>
                <div className="flex mt-4">
                  <div className="w-[50%]">
                    <div className="flex items-center gap-4">
                      <LineChart
                        width={500}
                        height={300}
                        data={dashDetails.listingsEntered}
                      >
                        <Line
                          type="linear"
                          dataKey="listingsCreated"
                          stroke="#B4C540"
                          strokeWidth={2}
                        />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                      </LineChart>
                      <span className="text-2xl text-[#575A6C]">
                        TOTAL
                        <br /> LISTINGS
                        <br /> ENTERED
                      </span>
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <div className="flex items-center gap-4">
                      <LineChart
                        width={500}
                        height={300}
                        data={dashDetails.buyersMatches}
                      >
                        <Line
                          type="linear"
                          dataKey="buyersMatched"
                          stroke="#B4C540"
                          strokeWidth={2}
                        />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                      </LineChart>
                      <span className="text-2xl text-[#575A6C]">
                        TOTAL
                        <br /> BUYER
                        <br /> MATCHES
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
