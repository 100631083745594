import React from "react";
import { FaCheck } from "react-icons/fa";

const Checkbox = ({ value, name, checked = false, onClick, label }) => {
  return (
    <div className="w-full flex items-center gap-2">
      <label className="relative w-4 h-4 mb-1">
        <input
          className="appearance-none peer w-full h-4 focus:border-primary active:border-primary rounded-sm bg-white border border-black checked:border-none checked:bg-primary"
          type="checkbox"
          value={value}
          name={name}
          checked={checked}
          onClick={onClick}
        />
        <FaCheck className="absolute right-0 bottom-0 hidden peer-checked:block w-4 h-3 text-white"/>
      </label>
      {label && <label className="whitespace-nowrap">{label}</label>}
    </div>
  );
};

export default Checkbox;