import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Button, Input, Modal, Popconfirm, Table } from "antd";
import {
  // CheckCircleOutlined,
  // CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  ADD_AMENITY,
  GET_COMPANY_AMENITIES,
  UPDATE_AMENITY,
} from "../../graphql/companyQueries";
import { useMutation, useQuery } from "@apollo/client";
import Toast from "../../components/Toast/Toast";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";

const Amenities = (props) => {
  const { companyId } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState("");
  const [selectAmenityId, setSelectAmenityId] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  // const [currentAmenity, setCurrentAmenity] = useState(null);

  const [amenities, setAmenities] = useState([]);

  const toast = useToast(4000);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setViewMode(null);
  };

  const showModal = () => {
    setOpen(true);
  };

  // const checkIsActive = (record) => {
  //   if (record.isActive) {
  //     return (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <CheckCircleOutlined style={{ fontSize: "20px", color: "green" }} />
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <CloseCircleOutlined style={{ fontSize: "20px", color: "red" }} />
  //       </div>
  //     );
  //   }
  // };

  const { loading, error, data } = useQuery(GET_COMPANY_AMENITIES, {
    variables: { companyId },
    fetchPolicy: "no-cache",
    onError(err) {
      Toast(
        "error",
        "There was an error loading the users. If the issue persists please contact the administrators"
      );
    },
    onCompleted(data) {
      setAmenities(data.getCompanyAmenities);
    },
  });

  const [addAmenity, { loading: addAmenityLoading }] = useMutation(
    ADD_AMENITY,
    {
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_COMPANY_AMENITIES,
          variables: {
            companyId,
          },
        },
      ],
      onCompleted(result) {
        setAmenities((prev) => [...prev, result.addAmenity]);
        toast("success", `Successfully saved ${result.addAmenity?.title}`);
        handleCancel();
      },
      onError(e) {
        toast("error", e?.message ?? "Something went wrong!");
      },
    }
  );

  const [updateAmenity, { loading: editAmenityLoading }] = useMutation(
    UPDATE_AMENITY,
    {
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_COMPANY_AMENITIES,
          variables: {
            companyId,
          },
        },
      ],
      onCompleted(result) {
        toast("success", `Successfully saved ${result.updateAmenity?.title}`);
        handleCancel();
      },
      onError(e) {
        toast("error", e?.message ?? "Something went wrong!");
      },
    }
  );

  const handleDelete = async (amenity) => {
    try {
      await updateAmenity({
        variables: {
          amenityInput: {
            _id: amenity._id,
            company: companyId,
            title: amenity.title,
            isActive: false,
          },
        },
      });

      setAmenities((prev) => prev.filter((item) => item._id !== amenity._id));
    } catch (error) {
      console.log("error in amenities/handleDelete");
    }
  };

  const columns = [
    {
      title: "Office",
      dataIndex: "title",
      key: "title",
      render: (text) => <p>{text}</p>,
    },
    // {
    //   title: "Active",
    //   dataIndex: "isActive",
    //   key: "isActive",
    //   render: (_, record) => checkIsActive(record),
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="table-action-btns">
          <Button
            className="border-primary text-primary"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedAmenity(record.title);
              setSelectAmenityId(record._id);
              showModal();
              setViewMode("Edit");
            }}
          />

          <Popconfirm
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            okType="danger"
            title="Are you sure to Delete?"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (error) toast("error", error.message);
  }, [error, toast]);

  return (
    <div>
      <Breadcrumb pageName="Amenities" />
      {!loading ? (
        <div className="py-6">
          <Table
            title={() => (
              <div className="flex justify-between items-center">
                <span className="font-bold text-lg">Amenities</span>
                <Button
                  className="border-primary text-primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setSelectedAmenity("");
                    setSelectAmenityId(null);
                    showModal();
                    setViewMode("Add");
                  }}
                >
                  Add New
                </Button>
              </div>
            )}
            bordered
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={amenities}
          />
        </div>
      ) : (
        <Spinner />
      )}
      <Modal
        open={open}
        title={`Amenity`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              type="primary"
              className="border-primary text-primary"
              disabled={
                addAmenityLoading ? true : editAmenityLoading ? true : false
              }
              onClick={async () => {
                if (!selectedAmenity.trim()) return;

                if (viewMode === "Add") {
                  addAmenity({
                    variables: {
                      amenityInput: {
                        company: companyId,
                        title: selectedAmenity,
                        isActive: true,
                      },
                    },
                  });
                } else {
                  await updateAmenity({
                    variables: {
                      amenityInput: {
                        _id: selectAmenityId,
                        company: companyId,
                        title: selectedAmenity,
                        isActive: true,
                      },
                    },
                  });

                  setAmenities((prev) =>
                    prev.map((item) => {
                      if (item._id !== selectAmenityId) return item;
                      return { ...item, title: selectedAmenity };
                    })
                  );
                }
              }}
            >
              Save
            </Button>
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        )}
      >
        <Input
          value={selectedAmenity}
          placeholder="Enter New Amenity"
          onChange={(event) => {
            setSelectedAmenity(event.target.value);
          }}
          maxLength={40}
        />
      </Modal>
    </div>
  );
};

export default Amenities;
