import { gql } from "@apollo/client";

export const ADD_USER = gql`
  mutation addUser($userInput: UserInput) {
    addUser(userInput: $userInput) {
      id
      firstName
      lastName
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userInput: UserInput) {
    updateUser(userInput: $userInput) {
      id
      firstName
      lastName
    }
  }
`;

export const DELETE_USER = gql`
  mutation ($userId: String) {
    deleteCompanyUser(userId: $userId) {
      id
      firstName
      lastName
    }
  }
`;
export const REACTIVE_USER = gql`
  mutation ($companyId: String, $userId: String) {
    reActiveUser(companyId: $companyId, userId: $userId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query ($userId: String!) {
    getUserById(userId: $userId) {
      id
      firstName
      lastName
      email
      phone
      role {
        _id
      }
      defaultOffice {
        _id
        name
      }
      licenseNo
    }
  }
`;
export const BULK_USER_UPLOAD = gql`
  mutation ($userBulk: [UserBulk]) {
    bulkUserUpload(userBulk: $userBulk) {
      totalSaved
      notSaved {
        FirstName
        LastName
      }
    }
  }
`;
export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($userId: String!, $password: String!) {
    updateUserPassword(userId: $userId, password: $password) {
      id
      firstName
      lastName
      email
    }
  }
`;
