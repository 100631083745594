import * as yup from "yup";
export const validationSchema = yup.object().shape({
  user: yup.string().required("Agent Name is required"),
  expiration: yup.date().required("Live date is required"),
  streetAddr: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip is required"),
  beds: yup
    .string()
    .matches(/^(10|\d(\.\d)?)$/, "No. of beds must not exceed 10")
    .required("Beds are required"),
  baths: yup
    .string()
    .matches(
      /^(10|\d(\.\d)?|[0-9])$/,
      "Baths must be a number or demical upto one decimal place and must not exceed 10."
    )
    .required("Baths are required"),
  price: yup.number().required("Price is required"),
  sqFt: yup.number().required("Square feet is required"),
  propertyType: yup.string().required("Property Type is required"),

  // .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, "Must contain 10 digits")
  // .nullable(),
});
