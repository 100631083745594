import { gql } from "@apollo/client";

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($notificationInput: NotificationInput) {
    sendNotification(notificationInput: $notificationInput)
  }
`;

export const GET_NOTIFICATION_USERS = gql`
  query {
    getNotificationUsers {
      id
      fullName
    }
  }
`;
