import SidebarContent from "./SidebarContent";

const Sidebar = () => {
	return (
		<aside className="z-30 flex-shrink-0 hidden w-64 overflow-y-auto bg-white shadow-lg lg:block">
			<SidebarContent />
		</aside>
	);
};

export default Sidebar;
