import { useNavigate } from "react-router-dom";

const TableHeader = ({ setShowModal, handleModalRender }) => {
	const navigate = useNavigate();
	return (
		<div className="flex justify-between py-6 px-4 md:px-6 xl:px-7.5 items-center">
			{/* Extra functions and Action Section  */}
			<div>
				<form action="https://formbold.com/s/unique_form_id" method="POST">
					<div className="relative">
						<div className="absolute top-1/2 left-1 -translate-y-1/2">
							<svg
								className="fill-gray-400"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
									fill=""
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
									fill=""
								/>
							</svg>
						</div>

						<input
							type="text"
							placeholder="Type to search..."
							className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none "
						/>
					</div>
				</form>
			</div>
			<div className="flex space-x-3">
				<button
					onClick={handleModalRender}
					className="flex space-x-2 items-center bg-primary text-white rounded-sm px-4 py-1 hover:opacity-90"
				>
					<svg
						className="fill-current"
						width="20"
						height="20"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
					</svg>
					<span>Add an Office</span>
				</button>
			</div>
		</div>
	);
};

export default TableHeader;
