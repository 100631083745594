import dayjs from "dayjs";
import { createContext, useContext, useState } from "react";

const ListingContext = createContext();

const DEFAULT_OPEN_HOUSE = {
  startTimeDayJS: null,
  endTimeDayJS: null,
  startTime: null,
  endTime: null,
  needHost: false,
};

export const ListingContextProvider = ({ children }) => {
  const [openHouses, setOpenHouses] = useState([DEFAULT_OPEN_HOUSE]);
  const [isOpenHouse, setIsOpenHouse] = useState(false);

  const addOpenHouse = () => {
    setOpenHouses([...openHouses, DEFAULT_OPEN_HOUSE]);
  };

  const removeOpenHouse = (index) => () => {
    setOpenHouses(openHouses.filter((_, i) => i !== index));
  };

  const handleOpenHouseDateChange = (index) => (value, dateString) => {
    setOpenHouses((prevOpenHouses) =>
      prevOpenHouses.map((openHouse, i) => {
        if (i !== index) return openHouse;
        return {
          ...openHouse,
          startTimeDayJS: dayjs(value[0].$d) ?? null,
          endTimeDayJS: dayjs(value[1].$d) ?? null,
          startTime: dateString[0],
          endTime: dateString[1],
        };
      })
    );
  };

  const handleNeedHostChange = (index) => () => {
    setOpenHouses((prevOpenHouses) =>
      prevOpenHouses.map((openHouse, i) => {
        if (i !== index) return openHouse;
        return {
          ...openHouse,
          needHost: !openHouse.needHost,
        };
      })
    );
  };

  const getFormattedOpenHouses = () =>
    openHouses
      .filter((oh) => oh.startTime || oh.endTime)
      .map(({ needHost, startTime, endTime }) => ({
        needHost,
        startTime: dayjs(startTime).toISOString(),
        endTime: dayjs(endTime).toISOString(),
      }));

  const isLastOpenHouse = (index) => index === openHouses.length - 1;

  const resetOpenHouses = () => {
    setOpenHouses([DEFAULT_OPEN_HOUSE]);
    setIsOpenHouse(false);
  };

  const values = {
    openHouses,
    isOpenHouse,
    isLastOpenHouse,
    setIsOpenHouse,
    addOpenHouse,
    removeOpenHouse,
    handleOpenHouseDateChange,
    handleNeedHostChange,
    getFormattedOpenHouses,
    setOpenHouses,
    resetOpenHouses,
  };

  return (
    <ListingContext.Provider value={values}>{children}</ListingContext.Provider>
  );
};

export const useListings = () => useContext(ListingContext);
