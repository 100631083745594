import { useMutation, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
// import { GET_COMPANIES } from "../../graphql/companyQueries";
import Spinner from "../../components/Spinner";

import {
  EXPORT_CSV_CONTENT,
  GET_COMPANY_DASH_DETAILS,
} from "../../graphql/companyQueries";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useParams } from "react-router-dom";
import { useToast } from "../../components/Toast/toastHook";
import MobileCard from "../../components/dashboard/MobileCard";
import { Line, LineChart, XAxis, YAxis, Tooltip } from "recharts";
import { Button, DatePicker, Dropdown, Modal, Select, Space } from "antd";
import { BsFiletypeCsv } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const EXPORT_NAMES = {
  BM: { label: "buyer_matches", value: "Buyer Matches" },
  LE: { label: "listings_entered", value: "Listings Entered" },
  BE: { label: "buyer_entered", value: "Buyers Entered" },
  AL: { label: "active_listings", value: "Active Listings" },
  AA: { label: "accounts_assigned", value: "Accounts Assigned" },
  AU: { label: "active_users", value: "Active Users" },
  IU: { label: "inactive_users", value: "Inactive Users" },
  ALU: { label: "all_users", value: "All Users" },
};

const CompanyDashboard = () => {
  const [dashDetails, setDashDetails] = useState({
    // Accounts
    companyUserLimit: 0,
    accountsAssigned: 0,
    accountsActivated: 0,
    percentAccountsActivated: 0,

    // Listing / Buyers
    totalActiveListings: 0,
    agentsEnteringListings: 0,
    agentsEnteringBuyers: 0,
    totalListings: 0,
    totalBuyersEntered: 0,
    totalBuyersMatched: 0,
    listingsEntered: [],
    buyersMatches: [],
  });

  const [exportName, setExportName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const { companyId } = useParams();

  // QUERIES & MUTATIONS
  const { loading, error, data } = useQuery(GET_COMPANY_DASH_DETAILS, {
    variables: { companyId, currentYear: currentYear },
    fetchPolicy: "no-cache",
  });

  const user = JSON.parse(localStorage.getItem("userInfo"));
  const isSuperAdmin = user?.role?.name === "SUPER_ADMIN";
  const [exportCSVContent] = useMutation(EXPORT_CSV_CONTENT, {
    onCompleted(result) {
      const {
        exportCSVContent: { csvContent },
      } = result;

      exportData(csvContent);
    },
    onError(e) {
      console.error("error in exportCSVContent", e);
      toast("error", "An issue occurred! Please try again.");
    },
  });

  const toast = useToast(5000);

  const { RangePicker } = DatePicker;

  const items = [
    {
      key: "1",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.BM.label)}>
          {EXPORT_NAMES.BM.value}
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.BE.label)}>
          {EXPORT_NAMES.BE.value}
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.LE.label)}>
          {EXPORT_NAMES.LE.value}
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.AL.label)}>
          {EXPORT_NAMES.AL.value}
        </span>
      ),
    },
    {
      key: "5",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.AU.label)}>
          {EXPORT_NAMES.AU.value}
        </span>
      ),
    },
    {
      key: "6",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.IU.label)}>
          {EXPORT_NAMES.IU.value}
        </span>
      ),
    },
    {
      key: "7",
      label: (
        <span onClick={() => setExportName(EXPORT_NAMES.ALU.label)}>
          {EXPORT_NAMES.ALU.value}
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (data) setDashDetails(data.getCompanyDashboardDetails);
    if (error) {
      console.error(error);
      toast("error", `${error.name}: ${error.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, currentYear]);

  const handleExportData = async () => {
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();
    setExportLoading(true);
    await exportCSVContent({
      variables: {
        exportInput: {
          startDate: startDateISO,
          endDate: endDateISO,
          exportName,
          companyId,
        },
      },
    });
    setExportLoading(false);
  };

  const handleCurrentYearHandler = (event) => {
    // Handle year change if needed
    const newYear = parseInt(event, 10);
    setCurrentYear(newYear);
  };
  const exportData = (csvContent) => {
    const startDateLocale = startDate.toLocaleDateString();
    const endDateLocale = endDate.toLocaleDateString();

    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${exportName}_${startDateLocale}_${endDateLocale}.csv`;

    a.click();

    window.URL.revokeObjectURL(url);
  };

  if (loading) return <Spinner />;
  return (
    <div className="">
      <Breadcrumb pageName={"Dashboard"} />
      <div className="flex w-full gap-3">
        <div className="min-w min-w-[1350px]">
          <Card>
            <div className="p-6">
              <div className="border-b-2 border-primary pb-2 mb-6 flex w-full justify-between items-center">
                <h1 className="text-3xl font-semibold text-secondary">
                  Overview
                </h1>
                <Dropdown
                  menu={{ items }}
                  placement="bottom"
                >
                  <Button
                    icon={<BsFiletypeCsv size={18} />}
                    // type="primary"
                  >
                    Export CSV
                  </Button>
                </Dropdown>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                  <MobileCard
                    text="Accounts Assigned"
                    value={dashDetails.accountsAssigned}
                  />
                  <MobileCard
                    text="Accounts Activated %"
                    value={`${dashDetails.percentAccountsActivated?.toFixed(
                      2
                    )}%`}
                  />
                  <MobileCard
                    text="Account Limit"
                    value={dashDetails.companyUserLimit}
                  />
                  {isSuperAdmin && (
                    <MobileCard
                      text="Total # of Buyers Entered"
                      value={dashDetails.totalBuyersEntered}
                    />
                  )}
                </div>
                <div className="flex flex-row gap-4">
                  <MobileCard
                    text="Active Listings"
                    value={dashDetails.totalActiveListings}
                  />
                  {isSuperAdmin && (
                    <MobileCard
                      text="Total Agents Entering Buyers"
                      value={`${dashDetails.agentsEnteringBuyers?.toFixed(2)}%`}
                    />
                  )}
                  <MobileCard
                    text="Total # of Buyer Matches"
                    value={dashDetails.totalBuyersMatched}
                  />
                  {isSuperAdmin && (
                    <MobileCard
                      text="Total Agents Entering Listings"
                      value={`${dashDetails.agentsEnteringListings?.toFixed(
                        2
                      )}%`}
                    />
                  )}
                </div>
                <div className="flex mt-4">
                  <div className="w-[50%]">
                    <div className="flex items-center gap-4">
                      <LineChart
                        width={500}
                        height={300}
                        data={dashDetails.listingsEntered}
                      >
                        <Line
                          type="linear"
                          dataKey="listingsCreated"
                          stroke="#B4C540"
                          strokeWidth={2}
                        />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                      </LineChart>
                      <span className="text-2xl text-[#575A6C]">
                        TOTAL
                        <br /> LISTINGS
                        <br /> ENTERED
                      </span>
                    </div>
                  </div>
                  <div className="w-[50%] relative">
                    <Space
                      wrap
                      className="absolute top-0 right-0"
                    >
                      <Select
                        defaultValue={currentYear}
                        style={{
                          width: 120,
                        }}
                        onChange={handleCurrentYearHandler}
                        options={Array.from({ length: 10 }, (_, index) => {
                          const currentYear = 2023;
                          const year = currentYear + index;
                          return { name: `Year ${year}`, value: year };
                        })}
                      />
                    </Space>
                    <div className="flex items-center gap-4">
                      <LineChart
                        width={500}
                        height={300}
                        data={dashDetails.buyersMatches}
                      >
                        <Line
                          type="linear"
                          dataKey="buyersMatched"
                          stroke="#B4C540"
                          strokeWidth={2}
                        />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                      </LineChart>
                      <span className="text-2xl text-[#575A6C]">
                        TOTAL
                        <br /> BUYER
                        <br /> MATCHES
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Modal
        open={exportName}
        title={`Export ${
          EXPORT_NAMES[
            exportName
              ?.split("_")
              ?.map((item) => item.toUpperCase()[0])
              ?.join("")
          ]?.value
        }`}
        onCancel={() => setExportName(null)}
        footer={() => (
          <div className="mt-3 flex">
            <Button
              type="primary"
              className="border-primary text-primary w-[73px]"
              loading={loading}
              onClick={handleExportData}
              disabled={(!startDate && !endDate) || exportLoading}
            >
              {exportLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={18} />
                </div>
              ) : (
                "Export"
              )}
            </Button>
            <Button
              key="back"
              onClick={() => setExportName(null)}
            >
              Cancel
            </Button>
          </div>
        )}
      >
        <div className="mt-4">
          <p>Please select a date range: </p>
          <div className="grid grid-cols-2 mt-5">
            <RangePicker
              format="DD/MM/YYYY"
              onChange={(value) => {
                setStartDate(value ? value[0].$d : null);
                setEndDate(value ? value[1].$d : null);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompanyDashboard;
