import * as yup from "yup";
export const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  // address: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  zip: yup.number().required("Required"),
  listing_extension_period: yup.string().matches(/^([1-9]|[1-8][0-9]|90)$/, 'Value must be between 1 and 90'),
  // phone: yup.string().required("Required"),

  // .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, "Must contain 10 digits")
  // .nullable(),
});
