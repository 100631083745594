import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_COMPANIES,
  GET_COMPANY,
  UPDATE_COMPANY,
} from "../../graphql/companyQueries";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";
import Breadcrumb from "../../components/Breadcrumb";
import CompanyForm from "../../components/administrator/CompanyForm";
import { useRef } from "react";

const EditCompany = () => {
  const { companyId } = useParams();
  const [company, setCompany] = useState({});
  const formikRef = useRef();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    companyName: "",
    userLimit: "",
    onBoardingPassword: "",
  });
  const toast = useToast(2000);
  const { data, loading, error } = useQuery(GET_COMPANY, {
    variables: { companyId },
  });

  const [updateCompany, { loading: saveLoad, error: saveError }] = useMutation(
    UPDATE_COMPANY,
    {
      refetchQueries: [{ query: GET_COMPANIES }],
      onCompleted(res) {
        toast(
          "success",
          `Success! ${res.updateCompany?.companyName} has been updated`
        );
        navigate("/administrator/companies");
      },
      onError(e) {
        toast("error", `Error! ${e.message}`);
      },
    }
  );

  const handleSave = ({
    companyName,
    userLimit,
    onBoardingPassword,
    defaultOffice,
    userAdmin,
  }) => {
    updateCompany({
      variables: {
        companyInput: {
          _id: companyId,
          companyName,
          userLimit,
          onBoardingPassword,
        },
      },
    });
  };

  useEffect(() => {
    if (data && data.getCompanyById) {
      setCompany(data.getCompanyById);
      setInitialValues({
        ...initialValues,
        ...data.getCompanyById,
      });
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error)
    toast("error", "There was an error loading the data. Please try again");
  return (
    <div>
      <Breadcrumb
        pageName={
          company ? `Update Company: ${company.companyName}` : "Update Company"
        }
      />
      <CompanyForm
        initialValues={initialValues}
        handleSave={handleSave}
        formikRef={formikRef}
        type="edit"
        loading={saveLoad}
      />
    </div>
  );
};

export default EditCompany;
