import { createContext, useEffect, useState } from "react";
import { client } from "../App";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [userData, setUserData] = useState({});

  const login = async (user) => {
    setIsLoading(true);
    setUserData(user);
    setUserToken(user.token);
    localStorage.setItem("userToken", user.token);
    localStorage.setItem("userInfo", JSON.stringify(user));
    // await AsyncStorage.setItem('userRefreshToken', user.refresh);
    setIsLoading(false);
    return;
  };

  const logout = async () => {
    setIsLoading(true);
    setUserToken(null);
    setUserData(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("userInfo");
    // await AsyncStorage.removeItem('userRefreshToken');
    setIsLoading(false);
    client.clearStore();
  };

  const IsLoggedIn = async () => {
    try {
      setIsLoading(true);
      let userToken = localStorage.getItem("userToken");
      let userInfo = localStorage.getItem("userInfo");
      userInfo = JSON.parse(userInfo);
      if (userInfo) {
        setUserToken(userToken);
        setUserData(userInfo);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(`isLogged in Error ${error}`);
    }
  };

  useEffect(() => {
    IsLoggedIn();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        IsLoggedIn,
        isLoading,
        userToken,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
