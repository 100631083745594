import Toast from "./Toast";
import { useToastStateContext } from "./ToastContext";

export default function ToastContainer() {
	const { toasts } = useToastStateContext();

	return (
		<div className="absolute top w-full md:w-[calc(100%-256px)] z-50">
			<div className="max-w-xl mx-auto">
				{toasts &&
					toasts.map((toast) => (
						<Toast
							id={toast.id}
							key={toast.id}
							type={toast.type}
							message={toast.message}
						/>
					))}
			</div>
		</div>
	);
}
