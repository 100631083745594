import * as yup from "yup";
export const validationSchema = yup.object().shape({
	firstName: yup.string().required("Required"),
	lastName: yup.string().required("Required"),
	email: yup.string().required("Required"),
	role: yup.string().required("Required"),
	defaultOffice: yup.string().required("Required"),

	// .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, "Must contain 10 digits")
	// .nullable(),
});
