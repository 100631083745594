import { useFormik } from "formik";
import { AiOutlineInfoCircle } from "react-icons/ai";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import { useToast } from "../../components/Toast/toastHook";
import NotificationForm from "../../components/administrator/NotificationForm";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_NOTIFICATION_USERS,
  SEND_NOTIFICATION,
} from "../../graphql/notificationQueries";
import AutocompleteCheckbox from "../../components/AutocompleteCheckbox";
import { GET_COMPANIES } from "../../graphql/companyQueries";
import { useEffect, useRef } from "react";

const Notifications = () => {
  const toast = useToast(4000);

  const [sendNotification, { data, loading, error }] = useMutation(
    SEND_NOTIFICATION,
    {
      onCompleted(result) {
        toast("success", `Notification send successfully!`);
      },
      onError(e) {
        console.error(e);
        toast("error", e?.message ?? "Something went wrong!");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      loading: false,
      toEveryOne: false,
      search: {
        company: "",
        user: "",
      },
      isOpen: {
        company: false,
        user: false,
      },
      selectedItems: { company: [], user: [] },
      companies: [],
      users: [],
    },
    validationSchema: yup.object().shape({
      title: yup.string().required("Title cannot be empty").max(100),
      description: yup.string().required("Description is Required").max(500),
    }),
    onSubmit: async (values, helper) => {
      formik.setFieldValue("loading", true);
      let notificationInput = {
        body: values.description,
        title: values.title,
      };
      if (values.selectedItems.company.length > 0) {
        notificationInput = {
          ...notificationInput,
          companyIDs: values.selectedItems.company,
        };
      }
      if (values.selectedItems.user.length > 0) {
        notificationInput = {
          ...notificationInput,
          userIDs: values.selectedItems.user,
        };
      }
      sendNotification({
        variables: {
          notificationInput,
        },
      });
      helper.resetForm();
      formik.setFieldValue("loading", false);
    },
  });

  const companyRes = useQuery(GET_COMPANIES, {
    fetchPolicy: "no-cache",
  });

  const userRes = useQuery(GET_NOTIFICATION_USERS, {
    fetchPolicy: "no-cache",
  });

  const dropdownRef = useRef(null);
  useEffect(() => {
    if (companyRes.data) {
      formik.setFieldValue("companies", companyRes.data.getAllCompanies);
    }
    if (userRes.data) {
      formik.setFieldValue("users", userRes.data.getNotificationUsers);
    }
  }, [companyRes.data, userRes.data]);

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        formik.setFieldValue("isOpen.company", false);
        formik.setFieldValue("isOpen.user", false);
      }
    };
    document.addEventListener("mousedown", handleBodyClick);

    return () => {
      document.removeEventListener("mousedown", handleBodyClick);
    };
  }, []);

  return (
    <div id="Notifications">
      <Breadcrumb pageName="Notifications" />
      <div className="flex gap-2 items-start md:items-center flex-col md:flex-row text-slate-400">
        <AiOutlineInfoCircle size="18" />
        <p className="text-sm">
          Facilitates the broadcasting of notifications to all registered users
          of the mobile application.
        </p>
      </div>
      <NotificationForm formik={formik} dropdownRef={dropdownRef} />
    </div>
  );
};

export default Notifications;
