import { useQuery } from "@apollo/client";
import { Select } from "antd";
import { Formik } from "formik";
import { useRef, useState } from "react";
import { AiFillCloseCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiSolidImageAdd } from "react-icons/bi";
import { useListings } from "../../context/ListingContext";
import { GET_COMPANY_AMENITIES } from "../../graphql/companyQueries";
import { uploadImages } from "../../services/upload";
import AutoComplete from "../AutoComplete";
import Input from "../Input";
import Loading from "../Loading";
import { useToast } from "../Toast/toastHook";
import OpenHouse from "./OpenHouse";
import { validationSchema } from "./form-validation";
import Checkbox from "../checkbox";

const ListingForm = ({
  formikRef,
  handleSave,
  initialValues,
  type,
  companyId,
  agents,
  loading,
}) => {
  const [imageLoading, setImageLoading] = useState(false);

  const inputRef = useRef(null);

  const toast = useToast(2000);

  const [imagesCount, setImagesCount] = useState(0);

  const { openHouses, isOpenHouse, setIsOpenHouse } = useListings();

  const onChange = (setFieldValue, values) => async (event) => {
    const files = event.target.files;

    if (imagesCount + files.length > 5) {
      inputRef.current.value = ""; // Clear the input

      return toast("error", "You can only upload 5 images at a time");
    }
    setImageLoading(true);
    try {
      setImagesCount(imagesCount + files.length);
      const response = await uploadImages(files);
      setFieldValue("imageURLs", [...values["imageURLs"], ...response.images]);
    } catch (error) {
      toast("error", error.message);
      console.error("error in uploadImages", error);
    }
    setImageLoading(false);
  };

  const { error, data } = useQuery(GET_COMPANY_AMENITIES, {
    variables: { companyId },
    // fetchPolicy: "no-cache",
    onError(err) {
      toast(
        "error",
        "There was an error loading the users. If the issue persists please contact the administrators"
      );
    },
  });

  const getOptions = () => {
    if (!data?.getCompanyAmenities) return [];

    return data?.getCompanyAmenities?.map((a) => ({
      label: a.title,
      value: a.title,
    }));
  };

  const getAgents = () => {
    if (!agents) return;

    return agents.map((a) => ({
      value: a.id,
      label: `${a.fullName} - ${a.email}`,
    }));
  };

  const filterOptions = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const removeImage = (setFieldValue, values, index) => () => {
    setFieldValue(
      "imageURLs",
      values["imageURLs"].filter((_, idx) => idx !== index)
    );

    setImagesCount((prevCount) => Math.max(0, prevCount - 1));
  };

  const getSelectedAmenities = (values) => {
    if (!data?.getCompanyAmenities) return [];

    return values["amenities"].map(
      (amenity) =>
        data.getCompanyAmenities.find((a) => a._id === amenity)?.title
    );
  };

  const selectAmenity = (setFieldValue, values) => (value) => {
    if (!data?.getCompanyAmenities) return;

    const mappedAmenities = value.map(
      (amenity) =>
        data.getCompanyAmenities.find((a) => a.title === amenity)?._id
    );

    setFieldValue("amenities", mappedAmenities);
  };

  const selectAgent = (setFieldValue) => (value) => {
    setFieldValue("user", value);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex, setFieldValue, values) => {
    e.preventDefault();
    const list = values.imageURLs || [];
    const oldIndex = parseInt(e.dataTransfer.getData("index"));
    const draggedImage = list[oldIndex];

    const newImageList = list.filter((_, index) => index !== oldIndex);
    newImageList.splice(newIndex, 0, draggedImage);
    setFieldValue("imageURLs", newImageList);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const payload = { ...values, baths: +values.baths, beds: +values.beds };
        handleSave(payload);
      }}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        errors,
        resetForm,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="p-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/2">
                <label className="mb-2 block text-black">
                  Agent to Post as
                </label>
                <div className="relative z-20 bg-transparent">
                  {/* <select
                    className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary"
                    type="text"
                    value={values["user"]}
                    name="user"
                    onChange={handleChange}
                  >
                    <option value="">Select an Agent</option>
                    {agents.length > 0
                      ? agents.map((a, i) => (
                          <option
                            value={a.id}
                            key={i}
                          >
                            {`${a.firstName} ${a.lastName}`}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <Select
                    style={{
                      width: "100%",
                      height: "50px",
                    }}
                    showSearch
                    placeholder="Select agent"
                    filterOption={filterOptions}
                    value={values["user"] || null}
                    options={getAgents()}
                    onChange={selectAgent(setFieldValue)}
                  />
                  {/* <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                    <svg
                      className="fill-current"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                          fill=""
                        ></path>
                      </g>
                    </svg>
                  </span> */}
                  {errors.user ? (
                    <div className="text-red text-sm py-2">{errors.user}</div>
                  ) : null}
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <label className="mb-2 block text-black ">Goes Live</label>
                <input
                  value={values["expiration"]}
                  name="expiration"
                  onChange={handleChange}
                  type="date"
                  // placeholder={placeholder}
                  className="w-full rounded-md border-[1px] border-[#d9d9d9] bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter "
                />
                {errors.expiration && (
                  <div className="text-red text-sm py-2">
                    {errors.expiration}
                  </div>
                )}
              </div>
            </div>

            {/* property type */}
            <div className="flex flex-col gap-2 mb-3">
              <div className="flex items-center gap-1">
                <div className="text-sm text-red pt-1">*</div>
                <div className="flex items-center gap-6 sm:gap-8 lg:gap-12">
                  <Checkbox
                    value={"sale"}
                    checked={values["propertyType"] === "sale"}
                    onClick={() =>
                      {
                        console.log("clicked")
                        setFieldValue(
                          "propertyType",
                          values["propertyType"] === "sale" ? "" : "sale"
                        )
                      }
                    }
                    label={"For Sale"}
                  />

                  <Checkbox
                    value={"rent"}
                    checked={values["propertyType"] === "rent"}
                    onClick={() =>
                      setFieldValue(
                        "propertyType",
                        values["propertyType"] === "rent" ? "" : "rent"
                      )
                    }
                    label={"For Rent"}
                  />
                </div>
              </div>
              {errors?.propertyType && (
                <div className="py-1 text-xs text-red">{errors.propertyType}</div>
              )}
            </div>

            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/2">
                <AutoComplete
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>

              <div className="w-full md:w-1/2">
                <Input
                  label={"City"}
                  name="city"
                  value={values["city"]}
                  onChange={handleChange}
                  type="text"
                  error={errors.city}
                  placeholder={"Enter the city"}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/2">
                <Input
                  label={"State/Province"}
                  name="state"
                  value={values["state"]}
                  onChange={handleChange}
                  error={errors.state}
                  type="text"
                  placeholder={"Enter the state"}
                />
              </div>

              <div className="w-full md:w-1/2">
                <Input
                  label={"Zip/Postal Code"}
                  name="zip"
                  value={values["zip"]}
                  error={errors.zip}
                  onChange={handleChange}
                  type="text"
                  placeholder={"Enter the zip or postal code"}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/2">
                <Input
                  label={"Number of Beds"}
                  name="beds"
                  error={errors.beds}
                  value={values["beds"]}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFieldValue("beds", value);
                  }}
                  type="text"
                  placeholder={"Enter the beds"}
                />
              </div>

              <div className="w-full md:w-1/2">
                <Input
                  label={"Number of Baths"}
                  name="baths"
                  error={errors.baths}
                  value={values["baths"]}
                  onChange={(e) => {
                    const value = e.target.value.replace(
                      /[^0-9.]|\.(?=.*\.)/g,
                      ""
                    ); // Allow digits and at most one decimal point
                    setFieldValue("baths", value);
                  }}
                  type="text"
                  placeholder={"Enter the baths"}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/2">
                <Input
                  label={"Price"}
                  name="price"
                  error={errors.price}
                  value={values["price"]}
                  onChange={handleChange}
                  type="number"
                  placeholder={"Enter the price"}
                />
              </div>
              <div className="w-full md:w-1/2">
                <Input
                  label={"Number of Years Built"}
                  name="yearBuilt"
                  value={values["yearBuilt"]}
                  onChange={handleChange}
                  type="number"
                  placeholder={"Enter the year built"}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/3">
                <Input
                  label={"Square Feet"}
                  name="sqFt"
                  error={errors.sqFt}
                  value={values["sqFt"]}
                  onChange={handleChange}
                  type="number"
                  placeholder={"Enter the square footage"}
                />
              </div>
              <div className="w-full md:w-1/3">
                <Input
                  label={"Number of Lot Size"}
                  name="lotSize"
                  value={values["lotSize"]}
                  onChange={handleChange}
                  type="number"
                  placeholder={"Enter the lot size"}
                />
              </div>
              <div className="w-full md:w-1/3">
                <Input
                  label={"Number of Acreage"}
                  name="acreage"
                  value={values["acreage"]}
                  onChange={handleChange}
                  type="number"
                  placeholder={"Enter the acreage"}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col">
              <label className="mb-2 block text-black ">Amenities</label>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                  height: "50px",
                }}
                placeholder="Select Amenities"
                value={getSelectedAmenities(values)}
                onChange={selectAmenity(setFieldValue, values)}
                options={getOptions()}
                className="mt-2"
              />
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full">
                <Input
                  label={"Notes"}
                  name="notes"
                  value={values["notes"]}
                  onChange={handleChange}
                  type="text area"
                  placeholder={"Enter a note"}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full">
                <Input
                  label="Buyer's Agent Comp"
                  name="buyer_commission"
                  value={values["buyer_commission"]}
                  onChange={handleChange}
                  type="text area"
                  placeholder={"Enter here..."}
                />
              </div>
            </div>
            <div className="mb-3 flex flex-col gap-6 md:flex-row">
              <div className="w-full md:w-1/2">
                <input
                  type="checkbox"
                  id="openHouse"
                  name="openHouse"
                  checked={isOpenHouse}
                  onChange={(event) => setIsOpenHouse(event.target.checked)}
                />
                <label
                  for="openHouse"
                  className="ml-2"
                >
                  Open House
                </label>
              </div>
            </div>

            <div className="flex flex-col gap-y-2 mb-4">
              {openHouses?.map((openHouse, index) => (
                <OpenHouse
                  key={index}
                  openHouse={openHouse}
                  isOpenHouse={isOpenHouse}
                  index={index}
                />
              ))}
            </div>

            <div className="mb-3 flex flex-col">
              <label className="mb-2 block text-black ">Images</label>
              <input
                type="file"
                ref={inputRef}
                accept="image/png, image/jpeg"
                onChange={onChange(setFieldValue, values)}
                multiple
                disabled={imagesCount >= 5}
                hidden
              />
              <div className="flex flex-wrap gap-4">
                {values["imageURLs"]?.map((image, index) => (
                  <div
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index, setFieldValue, values)}
                    className="flex relative items-center justify-center w-[84px] h-[84px]"
                    key={index}
                  >
                    <div onClick={removeImage(setFieldValue, values, index)}>
                      <AiFillCloseCircle
                        className="absolute top-[-10px] right-[-10px] hover:cursor-pointer"
                        size={20}
                        color="red"
                      />
                    </div>
                    <img
                      src={image}
                      alt=""
                      className="w-full block rounded object-cover h-full cursor-grabbing"
                    />
                  </div>
                ))}
                <div
                  className={`border w-max rounded border-primary p-4 flex justify-center items-center ${
                    imagesCount >= 5
                      ? "opacity-50 pointer-events-none"
                      : "hover:cursor-pointer"
                  }`}
                  onClick={() => inputRef.current.click()}
                >
                  <BiSolidImageAdd
                    size={50}
                    color="#B4C540"
                  />
                </div>
                {imageLoading && <Loading text="Uploading" />}
              </div>
            </div>
            <div className="mt-6 flex space-x-2">
              <button
                type="button"
                onClick={() => resetForm()}
                disabled={loading}
                className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={isSubmitting || loading}
                className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
              >
                {loading ? (
                  <div className="animate-spin">
                    <AiOutlineLoading3Quarters size={22} />
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default ListingForm;
