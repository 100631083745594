import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";
import TableHeader from "../../components/listings/TableHeader";
import {
  DELETE_LISTING,
  GET_COMPANY_LISTINGS,
} from "../../graphql/listingQueries";
import fullDate from "../../utils/fullDate";
import priceFormat from "../../utils/priceFormat";
import { BsFiletypeCsv } from "react-icons/bs";

const Listings = () => {
  const [listings, setListings] = useState([]);

  const { companyId } = useParams();
  const navigate = useNavigate();

  const toast = useToast(4000);

  const { loading, error, data } = useQuery(GET_COMPANY_LISTINGS, {
    variables: {
      companyId: companyId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!data?.getCompanyListings) return;
    setListings(data?.getCompanyListings);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    toast("error", error.message);
  });

  const [deleteListing, { loading: delLoading, error: delError }] = useMutation(
    DELETE_LISTING,
    {
      onCompleted(result) {
        toast("success", `Success: the listing has been removed`);
        setListings((prev) =>
          prev.filter((l) => l._id !== result.deleteListing._id)
        );
      },
    }
  );

  const handleDelete = (listingId) => {
    if (
      window.confirm(
        "Warning! Are you sure you would like to delete this listing? This action cannot be undone"
      )
    ) {
      deleteListing({ variables: { listingId } });
    }
    return;
  };

	const COLUMN_MAPPINGS = {
		firstName: "First Name",
		lastName: "Last Name",
		phone: "Phone",
		email: "Email",
		companyName: "Company Name",
		officeName: "Office Name",
		streetAddr: "Street Address",
		city: "City",
		state: "State",
		zip: "Zip",
		propertyType: "Property Type",
		price: "Price",
		sqFt: "Square Feet",
		baths: "Baths",
		beds: "Beds",
		yearBuilt: "Year Built",
		expiration: "Expiration",
		createdAt: "Created At",
		openHouses: "Open Houses",
	  };

	const handleExportAllColumns = () => {
		exportOpenHousesListings(Object.keys(COLUMN_MAPPINGS));
	}

	const exportOpenHousesListings = (checkedColumns) => {

    // Filter listings that have openHouses and the openHouses array is not empty
    const openHousesListings = listings.filter(
      (listing) => listing.hasOwnProperty("openHouses") && listing.openHouses?.length > 0
    );

    if (openHousesListings.length === 0) {
      toast("error", "No open houses listings exists.");
      return;
    }

    try {
      const openHousesListingsCSVData = [];
  
      openHousesListings.forEach((listing) => {
        const columnsData = {};
  
        for (const column of checkedColumns) {
          if (column === "companyName") {
            columnsData[COLUMN_MAPPINGS[column]] = listing.company.companyName ?? "";
            continue;
          }
  
          if (column === "officeName") {
            columnsData[COLUMN_MAPPINGS[column]] = listing.office.name ?? "";
            continue;
          }
  
          if (column === "firstName" || column === "lastName" || column === "phone" || column === "email") {
            columnsData[COLUMN_MAPPINGS[column]] = listing.user[column] ?? "";
            continue;
          }
  
          if (column === "expiration" || column === "createdAt") {
            const date = new Date(parseInt(listing[column], 10));
            const formatted = date.toLocaleDateString("en-US", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            });
  
            columnsData[COLUMN_MAPPINGS[column]] = formatted === "Invalid Date" ? "" : formatted;
            continue;
          }
  
          if (column === "openHouses") {
            const openHousesList = listing.openHouses
            .map(
              (oh) =>
                `(Start: ${new Date(oh.startTime).toLocaleString()} - End: ${new Date(
                  oh.endTime
                ).toLocaleString()} - Need Host: ${oh.needHost})`
            )
            .join(", ");
            columnsData[COLUMN_MAPPINGS[column]] = openHousesList;
            continue;
          }
  
          columnsData[COLUMN_MAPPINGS[column]] = listing[column] ?? "";
        }
  
        openHousesListingsCSVData.push(columnsData);
      });
  
      const csvData = openHousesListingsCSVData.map((listing) =>
        Object.values(listing)
          .map((value) => JSON.stringify(value))
          .join(",")
      );
  
      const csvHeaders = Object.keys(openHousesListingsCSVData[0]);
      csvData.unshift(csvHeaders.join(","));
  
      const csvContent = csvData.join("\n");
  
      const blob = new Blob([csvContent], { type: "text/csv" });
  
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement("a");
      a.href = url;
      a.download = "open_houses_listings.csv";
  
      a.click();
  
      window.URL.revokeObjectURL(url);
  
      toast("success", "Open houses listings exported successfully.");
    } catch (error) {
      console.log("error in exportOpenHousesListings", error.message);
      toast("error", "There was an error in exporting open houses listings.");
    }
  };
  

  if (loading) return <Spinner />;

  return (
    <div id="companies">
      <Breadcrumb pageName="Listings" />
      <div className="pb-6">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-medium text-black">
            Export Open Houses Listings: 
          </div>
          <BsFiletypeCsv
              size={40}
              className="hover:cursor-pointer hover:text-blue"
              onClick={handleExportAllColumns}
            />
        </div>
        <Card>
          <TableHeader />
          {/* Table Headings*/}
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left grid grid-cols-6">
                <th className="col-span-2 py-4 px-4 font-medium text-black xl:pl-11">
                  Address
                </th>
                <th className="col-span-1  py-4 px-4 font-medium text-black ">
                  Price
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Agent
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Live Date
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {listings?.length > 0 ? (
                listings.map((d, i) => {
                  return (
                    <tr
                      className="grid grid-cols-6"
                      key={i}
                    >
                      <td className="col-span-2 border-b border-[#eee] py-5 px-4 pl-9 xl:pl-11">
                        <div className="text-black flex-col">
                          <div>{`${d.streetAddr},`}</div>
                          <div>{`${d.city}, ${d.state}, ${d.zip}`}</div>
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">
                          ${priceFormat(d.price)}
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">{`${d.user?.firstName} ${d.user?.lastName}`}</div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">
                          {fullDate(d.expiration)}
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() =>
                              navigate(
                                `/company/${companyId}/listings/${d._id}`
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                              <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                            </svg>
                          </button>
                          <button
                            className="hover:text-primary"
                            onClick={() => handleDelete(d._id)}
                          >
                            <svg
                              className="fill-current"
                              width="20"
                              height="20"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                fill=""
                              />
                              <path
                                d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                fill=""
                              />
                              <path
                                d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                fill=""
                              />
                              <path
                                d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                fill=""
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="">
                  <td className="block flex h-screen w-full items-center justify-center">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default Listings;