import fyrstup_icon from "../assets/new_icon.png";
import {Link, useNavigate} from 'react-router-dom'
const ForgotPassword = () => {
    const navigate = useNavigate();

    const handleSubmit = (e)=>{
        e.preventDefault();
        navigate("/")
    }
    return (
    <div id="forgot-password" className="flex items-center" style={{minHeight: 'calc(100vh - 90px'}}>
        <div className="rounded-sm border border-stroke bg-white shadow-lg w-full md:w-3/4 lg:w-1/2 mx-auto">
            <div className="w-full md:w-3/4 border-stroke flex flex-col p-6 mx-auto">
                <h1 className="mb-4 text-2xl font-semibold text-gray-700 text-center">Need to reset your password?</h1>
                <div className="mb-4 text-center text-gray-500">Submit your email address below and you will receive an email with instructions to reset your password.</div>
                {/* FyrstUp Secondary Logo */}
                <img src={fyrstup_icon} alt="" className="mx-auto h-40"/>
                {/* Reset Password form */}
                <form action="submit" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="mb-2 block font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            placeholder="linda@mail.com"
                            className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none"
                            />
                    </div>
                   
                    <div className="mb-5">
                        <button className="w-full rounded-lg border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">Submit</button>
                    </div>                        
                </form>
                <Link to="/" className="text-sm font-medium text-primary hover:underline">Go Back?</Link>
            </div>
        </div>

    </div>)
}

export default ForgotPassword;