import { gql } from "@apollo/client";

export const ADD_OFFICE = gql`
	mutation addOffice($officeInput: OfficeInput) {
		addOffice(officeInput: $officeInput) {
			_id
			name
		}
	}
`;

export const UPDATE_OFFICE = gql`
	mutation updateOffice($officeInput: OfficeInput) {
		updateOffice(officeInput: $officeInput) {
			_id
			name
		}
	}
`;
