import { useMutation } from "@apollo/client";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useToast } from "../../components/Toast/toastHook";
import CompanyForm from "../../components/administrator/CompanyForm";
import { ADD_COMPANY, GET_COMPANIES } from "../../graphql/companyQueries";

const AddCompany = () => {
  const navigate = useNavigate();
  const [initialValues, seInitialValues] = useState({
    companyName: "",
    userLimit: "",
    onBoardingPassword: "",
    office: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      isDefault: true,
    },
    user: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });
  const formikRef = useRef();

  const toast = useToast(4000);

  const [addCompany, { data, loading, error }] = useMutation(ADD_COMPANY, {
    refetchQueries: [{ query: GET_COMPANIES }],
    onCompleted(result) {
      toast("success", `Successfully saved ${result.addCompany?.companyName}!`);
      formikRef.current?.resetForm();
      navigate("/administrator/companies");
    },
    onError(e) {
      toast("error", `Error! ${e.message}`);
    },
  });

  const handleSave = (values, reset) => {
    addCompany({
      variables: { companyInput: values },
    });
  };

  return (
    <div id="add-company">
      <Breadcrumb pageName="Add a Company" />
      <CompanyForm
        initialValues={initialValues}
        handleSave={handleSave}
        formikRef={formikRef}
        type="add"
        loading={loading}
      />
    </div>
  );
};

export default AddCompany;
