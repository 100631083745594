const priceFormat = (price) => {
	if (!price) {
		return;
	}

	const priceString = typeof price !== "number" ? price : price.toString();

	return priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default priceFormat;
