import { Formik } from "formik";
import Card from "../Card";
import Input from "../Input";
import {
  editCompanyValidationSchema,
  validationSchema,
} from "./form-validation";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CompanyForm = ({
  initialValues,
  handleSave,
  formikRef,
  type,
  loadingState,
  loading,
}) => {
  return (
    <div id="company-form" className="py-6">
      <Card>
        <div className="border-b border-stroke py-4 px-6">
          <h3 className="text-lg font-medium text-primary">
            Company Information
          </h3>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={
            type === "edit" ? editCompanyValidationSchema : validationSchema
          }
          onSubmit={async (values, resetForm) => {
            handleSave(values, resetForm);
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            resetForm,
            touched,
            errors,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-6">
                <div className="mb-2 flex flex-col gap-6 md:flex-row">
                  <div className="w-full md:w-1/2">
                    <Input
                      label={"Company Name"}
                      name="companyName"
                      value={values["companyName"]}
                      onChange={handleChange}
                      type="text"
                      error={errors.companyName}
                      placeholder={"Enter the company name"}
                    />
                  </div>

                  <div className="w-full md:w-1/6">
                    <Input
                      label={"User Limit"}
                      name="userLimit"
                      value={values["userLimit"]}
                      onChange={handleChange}
                      type="number"
                      error={errors.userLimit}
                      placeholder={"Enter Users"}
                    />
                  </div>
                  <div className="w-full md:w-2/6">
                    <Input
                      label={"Onboarding Password"}
                      name="onBoardingPassword"
                      value={values["onBoardingPassword"]}
                      onChange={handleChange}
                      type="text"
                      error={errors.onBoardingPassword}
                      placeholder={"Enter the onboarding password"}
                    />
                  </div>
                </div>

                {type === "add" ? (
                  <>
                    <div className="border-b border-stroke py-4 mb-2">
                      <h3 className="text-lg font-medium text-primary">
                        Primary Office Information
                      </h3>
                    </div>

                    <div className="mb-3 flex flex-col gap-6 md:flex-row">
                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Office Name"}
                          name="office.name"
                          value={values["office"].name}
                          onChange={handleChange}
                          type="text"
                          error={errors["office"]?.name}
                          placeholder={"Enter the office name"}
                        />
                      </div>

                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Office Address"}
                          name="office.address"
                          value={values["office"].address}
                          error={errors["office"]?.address}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the office address"}
                        />
                      </div>
                    </div>

                    <div className="mb-3 flex flex-col gap-6 md:flex-row">
                      <div className="w-full md:w-1/2">
                        <Input
                          label={"City"}
                          name="office.city"
                          value={values["office"].city}
                          error={errors["office"]?.city}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the city"}
                        />
                      </div>

                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Zip/Postal Code"}
                          name="office.zip"
                          value={values["office"].zip}
                          error={errors["office"]?.zip}
                          onChange={handleChange}
                          type="number"
                          placeholder={"Enter the office zip/postal code"}
                        />
                      </div>
                    </div>

                    <div className="mb-3 flex flex-col gap-6 md:flex-row">
                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Office State/Province"}
                          name="office.state"
                          value={values["office"].state}
                          error={errors["office"]?.state}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the state or province"}
                        />
                      </div>

                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Phone Number"}
                          name="office.phone"
                          value={values["office"].phone}
                          error={errors["office"]?.phone}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the phone number"}
                        />
                      </div>
                    </div>

                    <div className="border-b border-stroke py-4 mb-2">
                      <h3 className="text-lg font-medium text-primary">
                        Primary Company Administrator
                      </h3>
                    </div>

                    <div className="mb-3 flex flex-col gap-6 md:flex-row">
                      <div className="w-full md:w-1/2">
                        <Input
                          label={"First Name"}
                          name="user.firstName"
                          value={values["user"].firstName}
                          error={errors["user"]?.firstName}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the first name"}
                        />
                      </div>

                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Last Name"}
                          name="user.lastName"
                          value={values["user"].lastName}
                          error={errors["user"]?.lastName}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the last name"}
                        />
                      </div>
                    </div>

                    <div className="mb-3 flex flex-col gap-6 md:flex-row">
                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Email"}
                          name="user.email"
                          value={values["user"].email}
                          error={errors["user"]?.email}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Enter the email"}
                        />
                      </div>

                      <div className="w-full md:w-1/2">
                        <Input
                          label={"Phone Number"}
                          name="user.phone"
                          value={values["user"].phone}
                          error={errors["user"]?.phone}
                          onChange={handleChange}
                          type="number"
                          placeholder={"Enter the phone number"}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="mt-6 flex space-x-2">
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 h-[50px]"
                    disabled={loading}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 h-[50px]"
                  >
                    {loading ? (
                      <Spin
                        style={{
                          color: "white",
                        }}
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CompanyForm;
