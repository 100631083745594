import { Suspense, useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Main from "./Main";
import ThemedSuspense from "../components/ThemedSuspense";
import {
	Outlet,
	useLocation,
	useNavigate,
	useOutlet,
	useParams,
} from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Spinner from "../components/Spinner";
import { decodeToken } from "react-jwt";
import Header from "../components/Navbar/Header";

const ProtectedLayout = () => {
	const token = localStorage.getItem("userToken");
	const navigate = useNavigate();
	const outlet = useOutlet();
	let location = useLocation();
	const { companyId } = useParams();

	const canAccess = async () => {
		const user = JSON.parse(localStorage.getItem("userInfo"));
		const decoded = await decodeToken(token);
		if (decoded.role === "SUPER_ADMIN") return;
		else if (
			location.pathname.includes("administrator") &&
			decoded.role !== "SUPER_ADMIN"
		) {
			navigate(`/company/${user.company._id}/dashboard`);
		}
	};
	useEffect(() => {
		if (!token) navigate("/");
		else canAccess();
	}, [outlet]);

	if (!token) return navigate("/");
	return (
		<div className={`flex h-screen bg-gray-50`}>
			<Sidebar />
			<div className="flex flex-col flex-1 w-full">
				{/* We can place a navbar above main if we use a nav */}
				<Main>
					<Suspense fallback={<ThemedSuspense />} />
					{companyId ? <Header /> : null}

					{outlet}
				</Main>
			</div>
		</div>
	);
};

export default ProtectedLayout;
