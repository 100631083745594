import ToastContainer from "../components/Toast/ToastContainer";

const Main = ({ children }) => {
	return (
		<main className="h-full overflow-y-auto">
			<ToastContainer />
			<div className="container grid pt-4 px-6 mx-auto 2xl:px-10">
				{children}
			</div>
		</main>
	);
};

export default Main;
