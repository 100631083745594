import { useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GET_COMPANY_DETAILS } from "../../graphql/companyQueries";
import { useEffect, useState } from "react";
import Spinner from "../Spinner";
import { formatDate } from "../../utils/dateFormat";

const Header = () => {
  const { companyId } = useParams();
  const [company, setCompany] = useState({});
  const { error, loading, data } = useQuery(GET_COMPANY_DETAILS, {
    variables: { companyId },
  });
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  useEffect(() => {
    if (data) setCompany(data.getCompanyById);
  }, [data]);
  if (loading) return <Spinner />;

  return (
    <header
      className={`flex w-full text-secondary align-center pt-4 mb-2 ${
        user.role?.name === "SUPER_ADMIN" ? "justify-between" : "justify-end"
      }`}
    >
      {user?.role?.name === "SUPER_ADMIN" ? (
        <div className="">
          <Link
            onClick={() => navigate("/administrator/dashboard")}
            className="inline-flex bg-primary p-2 text-white rounded w-full font-semibold hover:opacity-90"
          >
            {/* <Icon className="w-5 h-5" aria-hidden="true" icon={'OutlineLogoutIcon'} /> */}
            <span className="mx-auto">{`<<< Super Admin Dashboard`}</span>
          </Link>
        </div>
      ) : null}
      <div className="text-2xl text-secondary text-end">
        <div>Welcome back, {company.companyName}!</div>
        <div className="text-xs py">
          FyrstUp user since: {formatDate(company.createdAt)}
        </div>
      </div>
    </header>
  );
};

export default Header;
