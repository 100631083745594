import fyrstuplogo from "../../assets/Fyrst_Up_Logo.png";

const Navbar = () => {
	return (
		<header className="bg-white text-white w-full drop-shadow-md" id="navbar">
			<nav className="relative container mx-auto p-3">
				<div className="flex items-center justify-between">
					<a className="h-14" id="fyrstup-logo">
						<img className="max-h-full" src={fyrstuplogo} alt="" />
					</a>
				</div>
			</nav>
		</header>
	);
};

export default Navbar;
