import { Suspense } from "react";
import { useState, useEffect } from "react";
import { decodeToken } from "react-jwt";
import Main from "./Main";
import ThemedSuspense from "../components/ThemedSuspense";
import { useNavigate, useOutlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";

const Layout = () => {
	const token = localStorage.getItem("userToken");
	const navigate = useNavigate();
	const outlet = useOutlet();
	const checkAuth = async () => {
		const decoded = await decodeToken(token);
		const user = JSON.parse(localStorage.getItem("userInfo"));
		if (decoded.role === "SUPER_ADMIN") navigate("/administrator/dashboard");
		else navigate(`/company/${user.company._id}/dashboard`);
	};
	useEffect(() => {
		if (token) checkAuth();
	}, [outlet]);

	return (
		<div id="Layout" className={`flex flex-col min-h-screen bg-gray-50`}>
			<Navbar />
			<div className="flex flex-col flex-1 w-full">
				{/* We can place a navbar above main if we use a nav */}
				<Main>
					<Suspense fallback={<ThemedSuspense />} />
					{outlet}
				</Main>
			</div>
		</div>
	);
};

export default Layout;
