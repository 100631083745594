import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { GET_COMPANY_USERS } from "../../graphql/companyQueries";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Toast from "../../components/Toast/Toast";
import Spinner from "../../components/Spinner";
import {
  Button,
  Modal,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Checkbox,
} from "antd";

import {
  ImportOutlined,
  FileOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { formatPhoneNumber } from "../../utils/phoneFormat";
import {
  EditOutlined as EditIcon,
  PlusOutlined as PlusIcon,
  ReloadOutlined as ReloadIcon,
  SearchOutlined as SearchIcon,
} from "@ant-design/icons";
import UserFormNew from "../../components/users/UserFormNew";
import {
  DELETE_USER,
  REACTIVE_USER,
  UPDATE_USER_PASSWORD,
} from "../../graphql/userQueries";
import { useToast } from "../../components/Toast/toastHook";
import BulkUserUpload from "./BulkUserUpload";
import { BsFiletypeCsv } from "react-icons/bs";

const INITIAL_SELECTED_COLUMNS = {
  firstName: false,
  fullName: false,
  phone: false,
  role: false,
  defaultOffice: false,
  lastName: false,
  email: false,
  lastLogin: false,
  createdAt: false,
};

const COLUMN_MAPPINGS = {
  firstName: "First Name",
  fullName: "Full Name",
  phone: "Phone",
  role: "Role",
  defaultOffice: "Office",
  lastName: "Last Name",
  email: "Email",
  lastLogin: "Last Login",
  createdAt: "Account Created",
};

const UsersNew = (props) => {
  const [open, setOpen] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [users, setUsers] = useState(null);
  const [agentStatus, setAgentStatus] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [agentsCount, setAgentsCount] = useState(0);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [resetAgent, setResetAgent] = useState(null);
  const [currentUserPassword, setCurrentUserPassword] = useState(null);
  const [isAddEditForm, setIsAddEditForm] = useState(false);
  const [showBulkImport, setShowBulkImport] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    INITIAL_SELECTED_COLUMNS
  );

  const toast = useToast(4000);

  const { companyId } = useParams();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCancelExportModal = () => {
    setExportModal(false);
    setSelectedColumns(INITIAL_SELECTED_COLUMNS);
  };

  const handledSelectedColumnsChange = (event) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const { loading, error, data, refetch } = useQuery(GET_COMPANY_USERS, {
    fetchPolicy: "no-cache",
    variables: { companyId },
    onError(err) {
      Toast(
        "error",
        "There was an error loading the users. If the issue persists please contact the administrators"
      );
    },
  });

  const tableColumnFilterProps = (dataIndex, filteredInfo) => ({
    filterDropdown: ({
      clearFilters,
      confirm,
      selectedKeys,
      setSelectedKeys,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          placeholder="Search"
          style={{ display: "block", marginBottom: 8 }}
          value={selectedKeys[0]}
        />

        <Space>
          <Button
            icon={<SearchIcon />}
            onClick={() => confirm()}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchIcon style={{ color: filtered ? "#1890ff" : null }} />
    ),
    filteredValue: filteredInfo[dataIndex] || null,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const tableColumnSorterProps = (dataIndex, sortedInfo) => ({
    sorter: (a, b) => a[dataIndex].localeCompare(b[dataIndex]),
    sortOrder: sortedInfo.columnKey === dataIndex && sortedInfo.order,
  });

  const isExportActive = (min = 3) => {
    let selectedCount = 0;

    for (const column in selectedColumns) {
      if (!selectedColumns[column]) continue;
      selectedCount += 1;
    }

    return !(selectedCount >= min);
  };

  useEffect(() => {
    if (data) {
      renderUsersData(agentStatus);
    }

    if (error) toast("error", error.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, agentStatus, error]);

  const renderUsersData = (status) => {
    if (status === "Active") {
      const activeUsers = data.getCompanyUsers.filter(
        (s) => s?.isPasswordReseted
      );
      setUsers(activeUsers);
      setAgentsCount(activeUsers.length);
    } else if (status == "Inactive") {
      const inActiveUsers = data.getCompanyUsers.filter(
        (s) => !s?.isPasswordReseted
      );
      setUsers(inActiveUsers);
      setAgentsCount(inActiveUsers.length);
    } else {
      setUsers(data.getCompanyUsers);
      setAgentsCount(data.getCompanyUsers.length);
    }
  };
  const onAgentStatus = (type) => {
    if (type === agentStatus) {
      setAgentStatus(null);
      return;
    }
    setAgentStatus(type);
  };
  const [deleteCompanyUser] = useMutation(DELETE_USER, {
    // refetchQueries: [
    //   {
    //     query: GET_COMPANY_USERS,
    //     variables: {
    //       companyId,
    //     },
    //   },
    // ],
    onCompleted(result) {
      toast(
        "success",
        `You have successfully deleted ${result.deleteCompanyUser.firstName}`
      );
      refetch({ companyId });
    },
  });
  const [reactivateCompanyUser] = useMutation(REACTIVE_USER, {
    // refetchQueries: [
    //   {
    //     query: GET_COMPANY_USERS,
    //     variables: {
    //       companyId,
    //     },
    //   },
    // ],
    onCompleted(result) {
      toast(
        "success",
        `You have successfully reactivated ${result.reActiveUser.firstName}`
      );
      refetch({ companyId });
    },
  });
  const handleDelete = async (userId) => {
    deleteCompanyUser({ variables: { userId } });
  };

  const reactivateUser = (userId) => {
    reactivateCompanyUser({ variables: { userId } });
  };

  const getAccessType = (role) => {
    if (role === "ADMIN") return "DASHBOARD";
    if (role === "ADMIN 1") return "DASHBOARD & APP";
    if (role === "AGENT") return "APP";
    return "UNKNOWN";
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text) => <p>{text}</p>,
      ...tableColumnFilterProps("fullName", filteredInfo),
      ...tableColumnSorterProps("fullName", sortedInfo),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <p>{formatPhoneNumber(text)}</p>,
    },
    {
      title: "Access Type",
      key: "role",
      dataIndex: "role",
      render: (_, { role }) => <p>{getAccessType(role.name.toUpperCase())}</p>,
    },
    {
      title: "Office",
      key: "defaultOffice",
      dataIndex: "defaultOffice",
      render: (_, { defaultOffice }) => (
        <p>{defaultOffice ? defaultOffice.name : "N/A"}</p>
      ),
    },
    {
      dataIndex: "email",
      key: "email",
      title: "Email",
      ...tableColumnFilterProps("email", filteredInfo),
      ...tableColumnSorterProps("email", sortedInfo),
    },
    {
      title: "Activated Accounts",
      key: "activated",
      dataIndex: "activated",
      filters: [],
      filteredValue: [agentStatus],
      // onFilter: (value, record) =>,
      filterDropdownVisible: false,

      filterIcon: (
        <div onClick={() => onAgentStatus()}>
          <FilterOutlined />
        </div>
      ),
      render: (_, { lastLogin, isPasswordReseted }) => {
        return (
          <div className="w-full flex justify-center">
            {isPasswordReseted ? (
              <CheckOutlined style={{ fontSize: "22px", color: "green" }} />
            ) : (
              <CloseOutlined style={{ fontSize: "22px", color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Account Created",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (_, { createdAt }) => {
        const userCreatedAt = new Date(parseInt(createdAt, 10));

        const formatted = userCreatedAt.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });

        return <p>{formatted}</p>;
      },
      ...tableColumnSorterProps("createdAt", sortedInfo),
    },
    {
      title: "Last Login",
      key: "lastLogin",
      dataIndex: "lastLogin",
      render: (_, { lastLogin }) => {
        const userCreatedAt = new Date(
          parseInt(lastLogin ? lastLogin : null, 10)
        );

        const formatted = userCreatedAt.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });

        return <p>{formatted !== "Invalid Date" ? formatted : "--"}</p>;
      },
      ...tableColumnSorterProps("lastLogin", sortedInfo),
    },
    {
      title: "Action",
      key: "x",
      render: (_, record) =>
        !record?.deletedAt ? (
          <div className="table-action-btns">
            <Button
              className="border-primary text-primary"
              icon={<EditIcon />}
              onClick={() => {
                setSelectedAgent(record);
                setIsAddEditForm(true);
              }}
            />
            <Popconfirm
              onConfirm={() => {
                setResetAgent(record);
                showModal();
              }}
              okText="Yes"
              okType="danger"
              title="Are you sure to reset password?"
            >
              <Button
                className="border-secondary text-secondary"
                icon={<ReloadIcon />}
              />
            </Popconfirm>

            <Popconfirm
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              okType="danger"
              title="Set user to Delete?"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Popconfirm
              onConfirm={() => reactivateUser(record.id)}
              okText="Yes"
              okType="danger"
              title="Sure to reactivate?"
            >
              <Tooltip title="Reactivate">
                <Button danger icon={<PlusSquareOutlined />} />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    if (extra.action === "filter") {
      setAgentsCount(extra.currentDataSource.length);
    }
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  }

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setAgentStatus(null);
    setAgentsCount(users.length);
  };

  const listView = () => (
    <div>
      <Breadcrumb pageName="Users" />
      {!loading ? (
        <div className="py-6">
          <div className="flex justify-between items-center mb-4">
            <div>
              <Space>
                <Button onClick={() => clearAll()}>
                  Clear Filters and Sorters
                </Button>
                <Button onClick={() => onAgentStatus("Active")}>
                  Show Active Users
                </Button>
                <Button onClick={() => onAgentStatus("Inactive")}>
                  Show Inactive Users
                </Button>
                <Button
                  // onClick={() => navigate(`/company/${companyId}/users-upload`)}
                  onClick={() => {
                    setSelectedAgent(null);
                    setShowBulkImport(true);
                  }}
                  icon={<ImportOutlined />}
                >
                  Bulk Agents Import
                </Button>
                <Button
                  icon={<FileOutlined />}
                  onClick={() => setExportModal(true)}
                >
                  Export Active Agents
                </Button>
              </Space>
            </div>
            <BsFiletypeCsv
              size={40}
              className="hover:cursor-pointer hover:text-blue"
              onClick={handleExportAllColumns}
            />
          </div>

          <Table
            bordered
            title={() => (
              <div className="flex justify-between items-center">
                <span className="font-bold text-lg">
                  {agentStatus != null
                    ? agentStatus === "Inactive"
                      ? "Inactive"
                      : "Active"
                    : "All"}{" "}
                  Users ({agentsCount})
                </span>
                <Button
                  className="border-primary text-primary"
                  icon={<PlusIcon />}
                  onClick={() => {
                    setSelectedAgent(null);
                    setIsAddEditForm(true);
                  }}
                >
                  Add New
                </Button>
              </div>
            )}
            columns={columns}
            dataSource={users}
            rowKey={(record) => record?.id}
            pagination={{ position: ["bottomCenter"] }}
            onChange={onChange}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );

  const handleOnUserFormCancel = () => {
    setSelectedAgent(null);
    setIsAddEditForm(false);
  };

  const userBulkImport = () => (
    <div>
      <BulkUserUpload
        refetchUsers={refetch}
        onCancel={() => {
          setShowBulkImport(false);
        }}
      />
    </div>
  );

  const userForm = () => (
    <UserFormNew
      users={users}
      companyId={companyId}
      selectedUser={selectedAgent}
      handleOnCancel={handleOnUserFormCancel}
      handleOnComplete={handleOnUserFormCancel}
      refetchUsers={refetch}
    />
  );

  const handleExportSelectedColumns = () => {
    const checkedColumns = Object.keys(selectedColumns).filter(
      (col) => selectedColumns[col]
    );

    exportAgents(checkedColumns);
  };

  const handleExportAllColumns = () => {
    exportAgents(Object.keys(COLUMN_MAPPINGS));
  };

  const exportAgents = (checkedColumns) => {
    try {
      const userCSVData = [];

      const activeUsers = data.getCompanyUsers.filter(
        (user) => !user?.deletedAt
      );

      activeUsers.forEach((user) => {
        const columnsData = {};

        for (const column of checkedColumns) {
          if (column === "role") {
            columnsData[COLUMN_MAPPINGS[column]] =
              user[column].name.toUpperCase();
            continue;
          }

          if (column === "defaultOffice") {
            columnsData[COLUMN_MAPPINGS[column]] = user[column].name ?? "";
            continue;
          }

          if (column === "lastLogin" || column === "createdAt") {
            const userCreatedAt = new Date(parseInt(user[column], 10));

            const formatted = userCreatedAt.toLocaleDateString("en-US", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            });

            columnsData[COLUMN_MAPPINGS[column]] =
              formatted === "Invalid Date" ? "" : formatted;
            continue;
          }

          columnsData[COLUMN_MAPPINGS[column]] = user[column] ?? "";
        }

        userCSVData.push(columnsData);
      });

      const csvData = userCSVData.map((user) =>
        Object.values(user)
          .map((value) => JSON.stringify(value))
          .join(",")
      );

      const csvHeaders = Object.keys(userCSVData[0]);
      csvData.unshift(csvHeaders.join(","));

      const csvContent = csvData.join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "user_data.csv";

      a.click();

      window.URL.revokeObjectURL(url);

      toast("success", "Active agents exported successfully.");
    } catch (error) {
      console.log("error in handleExportAgents", error.message);
      toast("error", "There was an error in exporting active agents.");
    }
  };

  const [updatePassword] = useMutation(UPDATE_USER_PASSWORD, {
    async onCompleted(result) {
      toast(
        "success",
        `Successfully updated password for ${resetAgent.fullName}!`
      );
      setResetAgent(null);
      setCurrentUserPassword(null);
    },
    onError(e) {
      console.error(e);
      toast(
        "error",
        "An issue occurred! Please check your credentials and try again"
      );
    },
  });

  return (
    <div>
      {!isAddEditForm
        ? !showBulkImport
          ? listView()
          : userBulkImport()
        : userForm()}
      <Modal
        open={open}
        title={`Reset Password for ${resetAgent?.fullName}`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              type="primary"
              className="border-primary text-primary"
              loading={loading}
              onClick={() => {
                handleOk();
                updatePassword({
                  variables: {
                    userId: resetAgent.id,
                    password: currentUserPassword,
                  },
                });
              }}
            >
              Save
            </Button>
            {/* <Button className="border-red text-red">Reset to Onboarding Password</Button> */}
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        )}
      >
        <Input
          value={currentUserPassword}
          placeholder="Enter New password"
          onChange={(event) => {
            setCurrentUserPassword(event.target.value);
          }}
        />
      </Modal>
      <Modal
        open={exportModal}
        title="Export Active Agents"
        onOk={handleCancelExportModal}
        onCancel={handleCancelExportModal}
        footer={() => (
          <div className="mt-3 flex">
            <Button
              type="primary"
              className="border-primary text-primary"
              loading={loading}
              onClick={() => {
                handleExportSelectedColumns();
                handleCancelExportModal();
              }}
              disabled={isExportActive()}
            >
              Export
            </Button>
            <Button key="back" onClick={handleCancelExportModal}>
              Cancel
            </Button>
          </div>
        )}
      >
        <div className="mt-4">
          <p>
            Please select 3 or more of the following fields you want to see
            exported:
          </p>
          <div className="grid grid-cols-2 mt-5">
            <div className="flex flex-col">
              <Checkbox
                name="firstName"
                checked={selectedColumns.firstName}
                onChange={handledSelectedColumnsChange}
              >
                First Name
              </Checkbox>
              <Checkbox
                name="fullName"
                checked={selectedColumns.fullName}
                onChange={handledSelectedColumnsChange}
              >
                Full Name
              </Checkbox>
              <Checkbox
                name="phone"
                checked={selectedColumns.phone}
                onChange={handledSelectedColumnsChange}
              >
                Phone
              </Checkbox>
              <Checkbox
                name="role"
                checked={selectedColumns.role}
                onChange={handledSelectedColumnsChange}
              >
                Role
              </Checkbox>
              <Checkbox
                name="defaultOffice"
                checked={selectedColumns.defaultOffice}
                onChange={handledSelectedColumnsChange}
              >
                Office
              </Checkbox>
            </div>
            <div className="flex flex-col">
              <Checkbox
                name="lastName"
                checked={selectedColumns.lastName}
                onChange={handledSelectedColumnsChange}
              >
                Last Name
              </Checkbox>
              <Checkbox
                name="email"
                checked={selectedColumns.email}
                onChange={handledSelectedColumnsChange}
              >
                Email
              </Checkbox>
              <Checkbox
                name="createdAt"
                checked={selectedColumns.createdAt}
                onChange={handledSelectedColumnsChange}
              >
                Account Created
              </Checkbox>
              <Checkbox
                name="lastLogin"
                checked={selectedColumns.lastLogin}
                onChange={handledSelectedColumnsChange}
              >
                Last Login
              </Checkbox>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsersNew;
