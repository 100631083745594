import Card from "../Card";
import Input from "../Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { validationSchema } from "./form-validation";

const UserForm = ({ initialValues, formikRef, handleSave, type, loadingState, roles, offices }) => {
  return (
    <div id="UserForm" className="p-6">
      <Card>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async (values, resetForm) => {
            handleSave(values, resetForm);
          }}
        >
          {({ values, errors, isSubmitting, handleChange, handleBlur, resetForm, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-6">
                <div className="mb-3 flex flex-col gap-6 md:flex-row">
                  <div className="w-full md:w-1/2">
                    <Input
                      label={"First Name"}
                      name="firstName"
                      value={values["firstName"]}
                      onChange={handleChange}
                      type="text"
                      error={errors.firstName}
                      placeholder={"Enter the first name"}
                    />
                  </div>

                  <div className="w-full md:w-1/2">
                    <Input
                      label={"Last Name"}
                      name="lastName"
                      error={errors.lastName}
                      value={values["lastName"]}
                      onChange={handleChange}
                      type="text"
                      placeholder={"Enter the last name"}
                    />
                  </div>
                </div>

                <div className="mb-3 flex flex-col gap-6 md:flex-row">
                  <div className="w-full md:w-1/3">
                    <Input
                      label={"Email"}
                      name="email"
                      error={errors.email}
                      value={values["email"]}
                      onChange={handleChange}
                      type="text"
                      placeholder={"Enter the email"}
                    />
                  </div>

                  <div className="w-full md:w-1/3">
                    <Input
                      label={"Phone Number"}
                      name="phone"
                      value={values["phone"]}
                      onChange={handleChange}
                      type="number"
                      placeholder={"Enter the phone number"}
                    />
                  </div>
                  <div className="w-full md:w-1/3">
                    <Input
                      label={"License Number"}
                      name="licenseNo"
                      value={values["licenseNo"]}
                      onChange={handleChange}
                      type="text"
                      placeholder={"Enter the license number"}
                    />
                  </div>
                </div>

                <div className="mb-3 flex flex-col gap-6 md:flex-row">
                  <div className="w-full md:w-1/2">
                    <label className="mb-2 block text-black">User Role</label>
                    <div className="relative z-20 bg-transparent">
                      <select
                        className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary"
                        name="role"
                        value={values["role"]}
                        onChange={handleChange}
                      >
                        <option value="">Select a role</option>
                        {roles.map((r, i) => (
                          <option value={r._id} key={i}>
                            {r.name}
                          </option>
                        ))}
                      </select>
                      <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""
                            ></path>
                          </g>
                        </svg>
                      </span>
                      {errors.role && <div className="text-xs text-red py-2">{errors.role}</div>}
                    </div>
                  </div>

                  <div className="w-full md:w-1/2">
                    <label className="mb-2 block text-black">Assigned Office</label>
                    <div className="relative z-20 bg-transparent">
                      <select
                        className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary"
                        name="defaultOffice"
                        value={values["defaultOffice"]}
                        onChange={handleChange}
                      >
                        <option value="">Select an office</option>
                        {offices.map((o, i) => (
                          <option value={o._id} key={i}>
                            {o.name}
                          </option>
                        ))}
                      </select>
                      <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""
                            ></path>
                          </g>
                        </svg>
                      </span>
                      {errors.defaultOffice && <div className="text-xs text-red py-2">{errors.defaultOffice}</div>}
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex space-x-2">
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline  outline-secondary hover:opacity-90"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    disabled={loadingState}
                    className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default UserForm;
