import dayjs from "dayjs";

// Add year to date using dayjs library
export const addYearToDate = (date) => {
	let d = new Date(+date);
	return dayjs(d).add(1, "year").format("MM/DD/YYYY");
};

export const formatDate = (date) => {
	let d = new Date(+date);
	return dayjs(d).format("MM/DD/YYYY");
};
