export const unmaskPhone = (phone) => phone?.replace(/[^0-9.]+/g, "");

export const formatPhoneNumber = (phoneNumber) => {
	// Filter non-numbers
	if (!phoneNumber) return "--";
	const string = phoneNumber?.toString();
	if (string.length !== 10) return "--";
	if (string) {
		const regex = () => string.replace(/[^0-9.]+/g, "");

		return `(${regex().slice(0, 3)}) ${regex().slice(3, 6)}-${regex().slice(
			6,
			10
		)}`;
	}
};
