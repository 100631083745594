import { useNavigate, useParams } from "react-router-dom";

const TableHeader = () => {
	const { companyId } = useParams();
	const navigate = useNavigate();
	return (
		<div className="flex justify-between py-6 px-4 md:px-6 xl:px-7.5 items-center">
			{/* Extra functions and Action Section  */}
			<div>
				<form action="https://formbold.com/s/unique_form_id" method="POST">
					<div className="relative">
						<div className="absolute top-1/2 left-1 -translate-y-1/2">
							<svg
								className="fill-gray-400"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
									fill=""
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
									fill=""
								/>
							</svg>
						</div>

						<input
							type="text"
							placeholder="Type to search..."
							className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none "
						/>
					</div>
				</form>
			</div>
			<div className="flex space-x-3">
				{/* <button className="flex space-x-2 bg-primary text-white rounded-sm px-4 py-1 hover:opacity-90">
					<svg
						className="fill-current"
						width="20"
						height="20"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.8754 11.6719C16.5379 11.6719 16.2285 11.9531 16.2285 12.3187V14.8219C16.2285 15.075 16.0316 15.2719 15.7785 15.2719H2.22227C1.96914 15.2719 1.77227 15.075 1.77227 14.8219V12.3187C1.77227 11.9812 1.49102 11.6719 1.12539 11.6719C0.759766 11.6719 0.478516 11.9531 0.478516 12.3187V14.8219C0.478516 15.7781 1.23789 16.5375 2.19414 16.5375H15.7785C16.7348 16.5375 17.4941 15.7781 17.4941 14.8219V12.3187C17.5223 11.9531 17.2129 11.6719 16.8754 11.6719Z"
							fill=""
						/>
						<path
							d="M8.55074 12.3469C8.66324 12.4594 8.83199 12.5156 9.00074 12.5156C9.16949 12.5156 9.31012 12.4594 9.45074 12.3469L13.4726 8.43752C13.7257 8.1844 13.7257 7.79065 13.5007 7.53752C13.2476 7.2844 12.8539 7.2844 12.6007 7.5094L9.64762 10.4063V2.1094C9.64762 1.7719 9.36637 1.46252 9.00074 1.46252C8.66324 1.46252 8.35387 1.74377 8.35387 2.1094V10.4063L5.40074 7.53752C5.14762 7.2844 4.75387 7.31252 4.50074 7.53752C4.24762 7.79065 4.27574 8.1844 4.50074 8.43752L8.55074 12.3469Z"
							fill=""
						/>
					</svg>
					<span>Export Listings</span>
				</button> */}

				<button
					onClick={() => navigate(`/company/${companyId}/add-listing`)}
					className="flex space-x-2 items-center bg-primary text-white rounded-sm px-4 py-1 hover:opacity-90"
				>
					<svg
						className="fill-current"
						width="20"
						height="20"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
					</svg>
					<span>Add a Listing</span>
				</button>
			</div>
		</div>
	);
};

export default TableHeader;
