import { useState } from "react";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";

const Modal = ({ children, title, setShowModal, showModal }) => {
  return (
    <div
      id="Modal"
      className=""
    >
      {showModal && (
        <div
          className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 min-h-screen max-h-full flex items-center justify-center bg-black/50"
          onClick={() => setShowModal(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full md:w-3/4 rounded-lg border border-stroke bg-white shadow-lg px-6 max-h-[800px] overflow-y-auto"
          >
            {/* Modal Header */}
            <ModalHeader title={title} />

            {/* Modal Body */}
            <div className="flex flex-col py-4 px-4 mb-2">{children}</div>

            {/* Modal Footer */}
            {/* <ModalFooter setShowModal={setShowModal} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
