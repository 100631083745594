import { FaMobileAlt } from "react-icons/fa";

const MobileCard = ({ value, text }) => {
  return (
    <div className="h-[120px] w-full bg-gray-100 flex items-center">
      <div className="w-[27%] h-full flex items-center justify-center">
        <FaMobileAlt
          size={64}
          className="text-[#3686C9]"
        />
      </div>
      <div className="w-[73%] h-full flex justify-center items-center">
        <div className="flex flex-col items-center  ">
          <span className="text-2xl font-bold text-[#3686C9]">{value}</span>
          <p className="text-[14px] text-[#575A6C]">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default MobileCard;

MobileCard.defaultProps = {
  value: 400,
  text: "Account Limit",
};
