import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			company {
				_id
				companyName
			}
			email
			firstName
			lastName
			token
			firstLogin
			role {
				_id
				name
			}
			id
		}
	}
`;

export const FIRST_TIME_RESET = gql`
	mutation firstTimePasswordReset(
		$onBoardingPassword: String!
		$email: String!
		$password: String!
	) {
		firstTimePasswordReset(
			onBoardingPassword: $onBoardingPassword
			email: $email
			password: $password
		) {
			ok
			error
		}
	}
`;
