import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="flex flex-col items-center align-center justify-center h-screen">
      <h1 className="text-6xl font-semibold text-gray-700 dark:text-gray-200">
        404
      </h1>
      <p className="text-gray-700 dark:text-gray-300">
        Agents are not allowed to login admin dashboard{" "}
        <Link
          className="text-purple-600 hover:underline dark:text-purple-300"
          to="/"
        >
          Go Back
        </Link>
        .
      </p>
    </div>
  );
};

export default Page404;
