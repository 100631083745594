import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { persistCache } from "apollo3-cache-persist";
import { setContext } from "@apollo/client/link/context";
import Spinner from "./components/Spinner";
import { useState, useEffect } from "react";
import { AuthProvider } from "./context/AuthContext";
import { ToastProvider } from "./components/Toast/ToastContext";
import { ListingContextProvider } from "./context/ListingContext";
import ErrorBoundary from "./components/ErrorBoundary";

const cache = new InMemoryCache();
//replace localhost with ur ip address, if you are testing this on Expo GO.

const httpLink = createHttpLink({
  // uri: "http://fyrstup-api-dev.eba-fznsmcyu.us-west-2.elasticbeanstalk.com/graphql/",
  uri: "https://api.fyrstup.com/",
  // uri: "http://localhost:5000/",
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("userToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});

const App = () => {
  const [loadingCache, setLoadingCache] = useState(true);

  useEffect(() => {
    persistCache({
      cache,
      storage: localStorage,
    }).then(() => setLoadingCache(false));
  }, []);

  if (loadingCache) return <Spinner />;

  return (
    <ErrorBoundary>
      <ListingContextProvider>
        <ApolloProvider client={client}>
          <AuthProvider>
            <ToastProvider>
              <RouterProvider
                router={router}
                future={{ v7_startTransition: true }}
              />
            </ToastProvider>
          </AuthProvider>
        </ApolloProvider>
      </ListingContextProvider>
    </ErrorBoundary>
  );
};

export default App;
